import React from "react";
import { RouteComponentProps, withRouter, useLocation } from "react-router";

import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react";
import {
  help,
  homeOutline,
  informationCircleOutline,
  logIn,
  logOut,
  person,
} from "ionicons/icons";

import { connect } from "../data/connect";
import { setDarkMode } from "../data/user/user.actions";

import "./Menu.css";

// const routes = {
//   appPages: [
//     { title: 'Schedule', path: '/tabs/schedule', icon: calendarOutline },
//     { title: 'Speakers', path: '/tabs/speakers', icon: peopleOutline },
//     { title: 'Map', path: '/tabs/map', icon: mapOutline },
//     { title: 'About', path: '/tabs/about', icon: informationCircleOutline }
//   ],
//   loggedInPages: [
//     { title: 'Account', path: '/account', icon: person },
//     { title: 'Support', path: '/support', icon: help },
//     { title: 'Logout', path: '/logout', icon: logOut }
//   ],
//   loggedOutPages: [
//     { title: 'Login', path: '/login', icon: logIn },
//     { title: 'Support', path: '/support', icon: help },
//     { title: 'Signup', path: '/signup', icon: personAdd }
//   ]
// };

const routes = {
  appPages: [
    { title: "Home", path: "/home", icon: homeOutline },
    { title: "About", path: "/about", icon: informationCircleOutline },
  ],
  loggedInPages: [
    // { title: "Account", path: "/account", icon: person },
    { title: "Support", path: "/support", icon: help },
    { title: "Sign Out", path: "/logout", icon: logOut },
  ],
  loggedOutPages: [
    { title: "Sign In", path: "/login", icon: logIn },
    { title: "Support", path: "/support", icon: help },
    // { title: "Signup", path: "/signup", icon: personAdd },
  ],
};

interface Pages {
  title: string;
  path: string;
  icon: string;
  routerDirection?: string;
}
interface StateProps {
  darkMode: boolean;
  isAuthenticatedAzure: string;
  isAuthenticatedOrch: string;
  menuEnabled: boolean;
}

interface DispatchProps {
  setDarkMode: typeof setDarkMode;
}

interface MenuProps extends RouteComponentProps, StateProps, DispatchProps {}

const Menu: React.FC<MenuProps> = ({
  darkMode,
  history,
  isAuthenticatedAzure,
  isAuthenticatedOrch,
  setDarkMode,
  menuEnabled,
}) => {
  const location = useLocation();

  function renderlistItems(list: Pages[]) {
    return list
      .filter((route) => !!route.path)
      .map((p) => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem
            detail={false}
            routerLink={p.path}
            routerDirection="none"
            className={
              location.pathname.startsWith(p.path) ? "selected" : undefined
            }
          >
            <IonIcon slot="start" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }

  return (
    <IonMenu type="overlay" disabled={!menuEnabled} contentId="main">
      <IonContent forceOverscroll={false}>
        <IonList lines="none">
          <IonListHeader>JDE Mobile Apps</IonListHeader>
          {renderlistItems(routes.appPages)}
        </IonList>
        <IonList lines="none">
          <IonListHeader>Account</IonListHeader>
          {/* {isAuthenticatedAzure == "1" && isAuthenticatedOrch == "1" */}
          {isAuthenticatedOrch == "1"
            ? renderlistItems(routes.loggedInPages)
            : renderlistItems(routes.loggedOutPages)}
          {/* <IonItem>
            <IonIcon slot="start" icon={moonOutline}></IonIcon>
            <IonLabel>Dark Mode</IonLabel>
            <IonToggle
              checked={darkMode}
              onClick={() => setDarkMode(!darkMode)}
            />
          </IonItem> */}
        </IonList>
        {/* <IonList lines="none">
          <IonListHeader>Tutorial</IonListHeader>
          <IonItem
            button
            onClick={() => {
              history.push("/tutorial");
            }}
          >
            <IonIcon slot="start" icon={hammer} />
            Show Tutorial
          </IonItem>
        </IonList> */}
      </IonContent>
    </IonMenu>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    isAuthenticatedAzure: state.user.isAuthenticatedAzure,
    isAuthenticatedOrch: state.user.isAuthenticatedOrch,
    menuEnabled: state.data.menuEnabled,
  }),
  mapDispatchToProps: {
    setDarkMode,
  },
  component: withRouter(Menu),
});
