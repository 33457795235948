import React from "react";
import {
  IonButton,
  IonCol,
  IonFooter,
  IonIcon,
  IonItem,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";

const PoaFooter: React.FC = (): JSX.Element => {
  return (
    <IonToolbar>
      <IonRow>
        <IonCol></IonCol>
        <IonCol className="ion-text-left">
          <IonItem>
            <IonButton id="open-approve-modal" size="default" color="success">
              <IonIcon slot="start" icon={checkmarkCircleOutline}></IonIcon>
              Approve
            </IonButton>
          </IonItem>
        </IonCol>
        <IonCol className="ion-text-right">
          <IonItem>
            <IonButton id="open-reject-modal" size="default" color="danger">
              <IonIcon slot="start" icon={closeCircleOutline}></IonIcon>
              Reject&nbsp;&nbsp;&nbsp;
            </IonButton>
          </IonItem>
        </IonCol>
        <IonCol></IonCol>
      </IonRow>
    </IonToolbar>
  );
};

export default PoaFooter;
