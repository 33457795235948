import React from "react";
import "./Home.scss";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import { connect } from "../data/connect";
import {
  pricetagsOutline,
  logInOutline,
  logOutOutline,
  searchOutline,
  printOutline,
} from "ionicons/icons";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Redirect } from "react-router";

// import assaAbloyImg from "/assets/img/speaker-background.png";

interface StateProps {
  isAuthenticatedAzure: string;
  isAuthenticatedOrch: string;
}

interface DispatchProps {}

interface HomeProps extends StateProps, DispatchProps {}

const HomePage: React.FC<HomeProps> = ({
  isAuthenticatedAzure,
  isAuthenticatedOrch,
}): JSX.Element => {
  console.log("start of Home page");
  console.log("isAuthenticatedAzure: " + isAuthenticatedAzure);
  console.log("isAuthenticatedOrch: " + isAuthenticatedOrch);
  // if (isAuthenticatedAzure != "1" || isAuthenticatedOrch != "1") {
  if (isAuthenticatedOrch != "1") {
    console.log("redirected from Home to Login page");
    return <Redirect to="/login" />;
  }

  return (
    <IonPage>
      <Header
        appTitle="JDE Mobile Apps"
        backButtonHref=""
        backIsShown={false}
      />
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow>
            <img src="/assets/img/assa-abloy-logo.jpg" className="center" />
          </IonRow>
          <IonRow>&nbsp;</IonRow>
          <IonRow>
            <IonCol>
              <IonButton expand="block" href="/inventory-inquiry">
                <IonIcon slot="start" icon={searchOutline}></IonIcon>
                Inventory Inquiry
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton expand="block" href="/label-printers">
                <IonIcon slot="start" icon={printOutline}></IonIcon>
                Label Printers
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton expand="block" href="/po-approval">
                <IonIcon slot="start" icon={logOutOutline}></IonIcon>
                PO Approval
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton expand="block" href="/price-inquiry">
                <IonIcon slot="start" icon={pricetagsOutline}></IonIcon>
                Price Inquiry
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    isAuthenticatedAzure: state.user.isAuthenticatedAzure,
    isAuthenticatedOrch: state.user.isAuthenticatedOrch,
  }),
  component: HomePage,
});
