import { LabelPrinterModel } from "../models/LabelPrinter";

export const labelPrintersData: LabelPrinterModel[] = [
  {
    description: "001 - Dummy Printer for Test Printing (p1-s1-1)",
    address: "NUL",
    server: "PD 2200",
    group: "Default",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "002 - PMI - Corbin Berlin (p2-s1-2)",
    address: "10.239.46.235",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "003 - PM43 - EMSO (p3-s1-3)",
    address: "10.246.30.201",
    server: "PD 2200",
    group: "EMSO",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "004 - PC43T - EMSO DISABLED (Bartender) (p4-s1-4)",
    address: "NUL",
    server: "PD 2200",
    group: "EMSO",
    driver: "Intermec EasyCoder PC41 (IPL)",
  },
  {
    description: "005 - PMI - Austell GA (p5-s1-5)",
    address: "10.2.21.190",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "006 - PMI - Austell GA (p6-s1-6)",
    address: "10.244.196.27",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "007 - PM43 - Sargent (p7-s1-7)",
    address: "10.90.73.235",
    server: "PD 2200",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "008 - PM4i - EMSO DISABLED (Bartender) (p8-s3-2)",
    address: "NUL",
    server: "PD 2198",
    group: "EMSO",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "009 - PMI - EMSO DISABLED (Bartender) (p9-s1-9)",
    address: "NUL",
    server: "PD 2200",
    group: "EMSO",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "010 - PMI - Char NC (p10-s1-10)",
    address: "10.239.147.60",
    server: "PD 2200",
    group: "Ameristar",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "011 - PC43T - Yale Berlin (p11-s1-11)",
    address: "10.203.11.3",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PC41 (IPL)",
  },
  {
    description: "012 - PMI - Austell (p12-s1-12)",
    address: "10.252.21.203",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "013 - PMI - Austell (p13-s1-13)",
    address: "10.252.21.19",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "014 - PMI - Pomoma - Ontario (p14-s1-14)",
    address: "10.237.60.45",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "014 - PMI - Pomoma - Ontario (p14-s4-16)",
    address: "10.237.60.45",
    server: "NP 2185",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "015 - PMI - Pomona - Ontario (p15-s1-15)",
    address: "10.237.60.46",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "016 - PMI - Seattle (p16-s1-16)",
    address: "10.249.240.28",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "017 - PM43 - Seattle (p17-s1-17)",
    address: "10.252.23.19",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "018 - PMI - Denver (p18-s1-18)",
    address: "10.237.52.60",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "019 - PM43 - Denver (p19-s1-19)",
    address: "10.237.52.65",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "020 - PMI - Chicago - Carol Stream (p20-s1-20)",
    address: "10.246.220.12",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "021 - PMI - Chicago - Carol Stream (p21-s1-21)",
    address: "10.246.220.37",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "022 - PM43 - EMSO (p22-s1-22)",
    address: "10.246.31.149",
    server: "PD 2200",
    group: "EMSO",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "023 - PM43 - EMSO (p23-s1-23)",
    address: "10.246.29.186",
    server: "PD 2200",
    group: "EMSO",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "024 - PM43 - EMSO DISABLED (Bartender) (p24-s1-24)",
    address: "NUL",
    server: "PD 2200",
    group: "EMSO",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "025 - PM43 - Medeco Canada (p25-s1-25)",
    address: "10.140.2.33",
    server: "PD 2200",
    group: "Canada",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "026 - PM43 - Medeco Canada (p26-s1-26)",
    address: "10.140.2.34",
    server: "PD 2200",
    group: "Canada",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "026 - PM43 - Medeco Canada (p26-s4-18)",
    address: "10.140.2.34",
    server: "NP 2185",
    group: "Canada",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "027 - PM43 - Medeco Canada (p27-s1-27)",
    address: "10.140.2.38",
    server: "PD 2200",
    group: "Canada",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "027 - PM43 - Medeco Canada (p27-s4-19)",
    address: "10.140.2.38",
    server: "NP 2185",
    group: "Canada",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "028 - PM43 - McKinney (p28-s1-28)",
    address: "10.203.30.26",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "029 - PM43 - McKinney (p29-s2-142)",
    address: "10.203.30.31",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "030 - PM43 - MCK (p30-s3-3)",
    address: "10.203.30.27",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "031 - PM43 - McKinney (p31-s1-31)",
    address: "10.203.30.28",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "032 - PM43 - McKinney (p32-s1-32)",
    address: "10.26.9.21",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "033 - PM43 - McKinney (p33-s1-33)",
    address: "10.26.8.68",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "034 - PM43 - McKinney (p34-s1-34)",
    address: "10.26.9.23",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "035 - PM43 - McKinney (p35-s1-35)",
    address: "10.203.30.32",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "036 - PM4i - Elmhurst (p36-s1-36)",
    address: "10.239.146.2",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "037 - PM43 - Elmhurst (p37-s1-37)",
    address: "10.239.146.8",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "038 - PM43 - McKinney (p38-s1-38)",
    address: "10.203.30.38",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "039 - PM43 - McKinney (p39-s1-39)",
    address: "10.203.30.29",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "040 - PM43 - McKinney (p40-s1-40)",
    address: "10.203.30.30",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "041 - PM43 - McKinney (p41-s2-143)",
    address: "10.100.8.253",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "042 - PM43 - MCK (p42-s3-4)",
    address: "10.100.8.254",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "043 - PM4i - Birmingham (p43-s1-43)",
    address: "10.244.196.8",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "044 - PM43 - Birmingham (p44-s1-44)",
    address: "10.244.196.14",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "045 - PM4i - New Haven (p45-s1-45)",
    address: "10.90.73.63",
    server: "PD 2200",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "046 - PM43 - MCK (p46-s3-5)",
    address: "10.203.30.12",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "047 - PM43 - McKinney (p47-s2-144)",
    address: "10.203.30.23",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "047 - PM43 - McKinney (p47-s4-13)",
    address: "NUL",
    server: "NP 2185",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "048 - PM43 - McKinney (p48-s1-48)",
    address: "10.239.44.238",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "049 - PM43 - Sargent (p49-s1-49)",
    address: "10.90.74.20",
    server: "PD 2200",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "049 - PM43 - Sargent (p49-s4-4)",
    address: "10.90.74.20",
    server: "NP 2185",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "050 - PM4i - Rockwood (p50-s1-50)",
    address: "10.155.4.13",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "051 - PM4i - Easton (p51-s1-51)",
    address: "10.252.20.35",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "052 - PM4i - Easton (p52-s1-52)",
    address: "10.252.20.19",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "053 - PM4i - Dallas (p53-s1-53)",
    address: "10.246.224.12",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "054 - PM4i - Dallas (p54-s1-54)",
    address: "10.246.224.37",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "055 - PM4i - Houston (p55-s1-55)",
    address: "10.239.244.35",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "056 - PM4i - Houston (p56-s1-56)",
    address: "10.239.244.36",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "057 - PC43t - EMSO (p057-s2-54)",
    address: "10.246.31.65",
    server: "PD 2197",
    group: "EMSO",
    driver: "Intermec IPL",
  },
  {
    description: "058 - PM43 - Berlin (p58-s1-58)",
    address: "10.203.2.10",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "059 - PM43 - Berlin (p59-s1-59)",
    address: "10.203.2.11",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "060 - PM43 - Yale (p60-s1-60)",
    address: "10.203.30.5",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "060 - PM43 - Yale (p60-s4-11)",
    address: "10.203.30.5",
    server: "NP 2185",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "061 - PM43 - Yale (p61-s1-61)",
    address: "10.203.4.1",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "061 - PM43 - Yale (p61-s4-8)",
    address: "10.203.4.1",
    server: "NP 2185",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "062 - PM43 - Dallas (p62-s1-62)",
    address: "10.246.224.39",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "063 - PM43 - DCG #B1 (p63-s1-63)",
    address: "10.115.39.183",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "064 - PM43 - DCG (p64-s1-64)",
    address: "10.115.39.47",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "065 - PM43 - DCG (p65-s1-65)",
    address: "10.115.38.26",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "066 - PM43 - Aurora (p66-s1-66)",
    address: "10.237.52.67",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "067 - PM43 - Aurora (p67-s3-6)",
    address: "10.237.52.68",
    server: "PD 2198",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "068 - PM43 - Ontario (p68-s1-68)",
    address: "10.237.60.49",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "069 - PM43 - Ontario (p69-s1-69)",
    address: "10.237.60.39",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "070 - ZT230 - Bruken (p70-s1-70)",
    address: "10.103.44.104",
    server: "PD 2200",
    group: "Default",
    driver: "Zebra ZPLII",
  },
  {
    description: "070 - ZT230 - Bruken (p70-s4-17)",
    address: "10.103.44.104",
    server: "NP 2185",
    group: "Default",
    driver: "Zebra ZPLII",
  },
  {
    description: "071 - PM43 - DCG (p71-s2-146)",
    address: "10.115.37.1",
    server: "PD 2197",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "072 - PM43 - RKW (p72-s1-72)",
    address: "10.155.4.12",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "073 - PM43 - Yale (p73-s1-73)",
    address: "10.239.44.250",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "074 - PM43 - Yale Chicago (p74-s2-147)",
    address: "10.246.220.58",
    server: "PD 2197",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "075 - PM43 - Yale Chicago (p75-s1-75)",
    address: "10.246.220.59",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "076 - PM43 - Yale Berlin (p76-s2-148)",
    address: "10.203.1.23",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "077 - PM43 - Yale Berlin (p77-s1-77)",
    address: "10.203.1.25",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "078 - PM43 - DCG #C2 (p78-s1-78)",
    address: "10.115.38.42",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "079 - PM43 - Yale (p79-s1-79)",
    address: "10.203.1.55",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "080 - PM43 - Yale (p80-s2-149)",
    address: "10.203.1.122",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "080 - PM43 - Yale AV (p80-s5-149)",
    address: "10.203.1.122",
    server: "PD BLN7 *ACTIVE*",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "081 - PM43 - Birmingham (p81-s1-81)",
    address: "10.244.196.19",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "082 - PM43 - Yale Dallas (p82-s3-7)",
    address: "10.246.224.78",
    server: "PD 2198",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "083 - PM43 - Yale Dallas (p83-s2-150)",
    address: "10.246.224.79",
    server: "PD 2197",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "084 - PM43 - DCG #B7 (p84-s1-84)",
    address: "10.115.39.64",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "085 - PM43 - DCG #E4 (p85-s3-8)",
    address: "10.115.39.161",
    server: "PD 2198",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "086 - PM43 - DCG (p86-s2-151)",
    address: "10.115.8.102",
    server: "PD 2197",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "087 - PM43 - DCG #K7 (p87-s1-87)",
    address: "10.115.39.217",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "088 - PM43 - RWD (p88-s1-88)",
    address: "10.245.79.76",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "089 - PM4i - Yale Ontario (p89-s1-89)",
    address: "10.237.60.74",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "090 - PM43 - Yale Ontario (p90-s1-90)",
    address: "10.237.60.75",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "091 - PM43 - Yale Austell (p91-s1-91)",
    address: "10.252.21.222",
    server: "PD 2200",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "092 - PM43 - Yale Austell (p92-s3-9)",
    address: "10.252.21.223",
    server: "PD 2198",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "093 - PM43 - Yale Berlin (p93-s2-152)",
    address: "10.203.39.190",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "094 - PM43 - Yale Berlin (p94-s1-94)",
    address: "10.203.1.51",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "095 - PM43 - DCG #A8 (p95-s1-95)",
    address: "10.115.39.114",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "096 - PM43 - DCG #C2 (p96-s3-10)",
    address: "10.115.8.30",
    server: "PD 2198",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "097 - PM43 - DCG #C3 (p97-s1-97)",
    address: "10.115.8.52",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "098 - PM43 - DCG #D8 (p98-s1-98)",
    address: "10.115.39.93",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "099 - PM43 - DCG #D9 (p99-s1-99)",
    address: "10.115.39.202",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "0DO NOT USE - OLD 176 - DCG (pDO-s3-33)",
    address: "NUL",
    server: "PD 2198",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "0DO NOT USE - OLD 179 - DCG (pDO-s3-34)",
    address: "NUL",
    server: "PD 2198",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "0DO NOT USE - OLD 212 - DCG (pDO-s3-39)",
    address: "NUL",
    server: "PD 2198",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "100 - PM4i - Yale Berlin (p100-s1-100)",
    address: "NUL",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "100 - PM4i - Yale Berlin (p100-s5-31)",
    address: "10.203.3.3",
    server: "PD BLN7 *ACTIVE*",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "101 - PM4i - Yale Berlin (p101-s1-101)",
    address: "10.203.30.21",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "102 - Pm4i - Yale Berlin (p102-s2-153)",
    address: "10.203.30.22",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "103 - PM4i - Yale Berlin (p103-s1-103)",
    address: "10.203.30.25",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "104 - PM4i - Yale Berlin (p104-s1-104)",
    address: "10.203.1.47",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "105 - PM43- Yale Berlin (p105-s1-105)",
    address: "10.203.1.57",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "106 - PM4i - Yale Berlin (p106-s1-106)",
    address: "10.203.1.52",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "107 - PM4i - Yale Berlin (p107-s1-107)",
    address: "10.203.1.15",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "108 - PM4i - Yale Berlin (p108-s1-108)",
    address: "10.203.30.20",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "109 - PM4i - Yale Berlin (p109-s1-109)",
    address: "10.203.39.166",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "109 - PM4i - Yale Berlin AV (p109-s5-40)",
    address: "10.203.39.166",
    server: "PD BLN7 *ACTIVE*",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "110 - PM4i - Yale Berlin (p110-s1-110)",
    address: "10.203.30.44",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "111 - PM4i - Yale Berlin (p111-s1-111)",
    address: "10.239.44.33",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "112 - PM4i - Yale Berlin (p112-s1-112)",
    address: "10.203.4.3",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "113 - PM43 - Yale Berlin AV (p113-s5-44)",
    address: "10.203.1.72",
    server: "PD BLN7 *ACTIVE*",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "114 - PM4i - Yale Berlin (p114-s1-114)",
    address: "10.203.1.69",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "114 - PM4i - Yale Berlin AV (p114-s5-45)",
    address: "10.203.1.69",
    server: "PD BLN7 *ACTIVE*",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "115 - PM4i - Yale Berlin AV (p115-s5-46)",
    address: "10.203.1.90",
    server: "PD BLN7 *ACTIVE*",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "116 - PM4i - Yale Berlin AV (p116-s5-47)",
    address: "10.203.30.80",
    server: "PD BLN7 *ACTIVE*",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "117 - PM4i - Yale Berlin (p117-s5-48)",
    address: "10.203.1.64",
    server: "PD BLN7 *ACTIVE*",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "117 - PM4i - Yale Berlin NA (p117-s2-113)",
    address: "NUL",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "118 - PC43T - Yale Berlin (p118-s1-118)",
    address: "10.203.40.131",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PC41 (IPL)",
  },
  {
    description: "119 - PM4i - Yale Berlin AV (p119-s5-50)",
    address: "10.203.0.164",
    server: "PD BLN7 *ACTIVE*",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "120 - PM43 - Yale Berlin (p120-s3-16)",
    address: "NUL",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "120 - PM43 - Yale Berlin AV (p120-s5-51)",
    address: "10.203.1.63",
    server: "PD BLN7 *ACTIVE*",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "121 - PM43 Sargent (p121-s1-121)",
    address: "10.90.74.149",
    server: "PD 2200",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "122 - PC43T - Yale Berlin (p122-s3-17)",
    address: "10.203.38.150",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PC41 (IPL)",
  },
  {
    description: "123 - PM43 - DCG #C3 (p123-s2-154)",
    address: "10.115.39.160",
    server: "PD 2197",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "124 - PM4i - Yale Berlin AV (p124-s5-53)",
    address: "10.203.0.80",
    server: "PD BLN7 *ACTIVE*",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "124 - PM4i - Yale Berlin no (p124-s3-18)",
    address: "NUL",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "125 - PM43 - Yale Berlin (p125-s3-19)",
    address: "10.203.0.78",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "126 - PM43 - Yale Berlin (p126-s2-155)",
    address: "10.203.0.155",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "126 - PM43 - Yale Berlin (p126-s4-15)",
    address: "10.203.0.155",
    server: "NP 2185",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "127 - PM43 - Yale Berlin (p127-s3-20)",
    address: "10.203.30.19",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "128 - PM43 - DCG #E6 (p128-s1-128)",
    address: "10.115.39.189",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "129 - PM43 - DCG #E7 (p129-s1-129)",
    address: "10.115.38.168",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "130 - PM43 - DCG #G2 (p130-s1-130)",
    address: "10.115.8.37",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "131 - PM43 - DCG #G2 (p131-s1-131)",
    address: "10.115.8.38",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "132 - PM43 - DCG #G3 (p132-s1-132)",
    address: "10.115.8.54",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "133 - PM43 - DCG #G3 (p133-s3-21)",
    address: "10.115.38.121",
    server: "PD 2198",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "134 - PM43 - DCG #G5 (p134-s1-134)",
    address: "10.115.39.7",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "135 - PM43 - DCG #H2 (p135-s2-156)",
    address: "10.115.39.183",
    server: "PD 2197",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "136 - PM43 - DCG #H2 (p136-s1-136)",
    address: "10.115.38.255",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "137 - PM43 - DCG #H3 (p137-s1-137)",
    address: "10.115.8.6",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "138 - PM43 - DCG #H3 (p138-s1-138)",
    address: "10.115.8.9",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "139 - PM43 - DCG #H6 (p139-s1-139)",
    address: "10.115.8.46",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "140 - PM4i - Rockwood (p140-s1-140)",
    address: "10.245.79.47",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "141 - PM4i - Rockwood (p141-s1-141)",
    address: "10.245.79.70",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "142 - PM4i - RWOOD (p142-s3-22)",
    address: "10.245.79.67",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "143 - PM4i - Rockwood (p143-s1-143)",
    address: "10.245.79.50",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "144 - PM4i - Rockwood (p144-s1-144)",
    address: "10.245.79.49",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "145 - PM4i - RWOOD (p145-s3-23)",
    address: "10.245.78.32",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "146 - PM4i - Rockwood (p146-s1-146)",
    address: "10.245.79.52",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "147 - PM4i - Rockwood (p147-s1-147)",
    address: "10.245.79.41",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "148 - PM4i - Rockwood (p148-s1-148)",
    address: "10.245.79.69",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "149 - PM4i - RWOOD (p149-s2-157)",
    address: "10.245.79.42",
    server: "PD 2197",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "150 - PM4i - Rockwood (p150-s1-150)",
    address: "10.245.79.75",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "150 - PM4i - Rockwood (p150-s4-9)",
    address: "10.245.79.75",
    server: "NP 2185",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "151 - PM4i - RWOOD (p151-s3-24)",
    address: "10.245.79.56",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "151 - PM4i - RWOOD (p151-s4-10)",
    address: "10.245.79.56",
    server: "NP 2185",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "152 - PM4i - RWOOD (p152-s3-25)",
    address: "10.245.79.72",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "153 - PM4i - RWOOD (p153-s3-26)",
    address: "10.245.79.73",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "154 - PM4i - Rockwood (p154-s1-154)",
    address: "10.245.79.43",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "155 - PM4i - RWOOD (p155-s3-27)",
    address: "10.245.79.71",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "156 - PM4i - RWOOD (p156-s2-158)",
    address: "10.245.79.53",
    server: "PD 2197",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "157 - PM4i - Rockwood (p157-s1-157)",
    address: "10.245.79.46",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "158 - PM4i - Rockwood (p158-s1-158)",
    address: "10.245.79.45",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "159 - PM43 - RWOOD (p159-s3-28)",
    address: "10.245.79.110",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "160 - PM43 - RWOOD (p160-s3-29)",
    address: "10.245.79.111",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "161 - PM43 - RWOOD (p161-s3-30)",
    address: "10.245.79.112",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "162 - PM4i - Rockwood (p162-s1-162)",
    address: "10.245.79.54",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "163 - PM4i - Rockwood (p163-s1-163)",
    address: "10.245.79.44",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "164 - PM43 - RWOOD (p164-s3-31)",
    address: "10.245.79.113",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "165 - PM43 - Rockwood (p165-s1-165)",
    address: "10.245.79.114",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "166 - PM43 - DCG #I2 (p166-s2-159)",
    address: "10.115.8.29",
    server: "PD 2197",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "167 - PM43 - DCG #I2 (p167-s1-167)",
    address: "10.115.8.32",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "168 - PM43 - DCG #I3 (p168-s1-168)",
    address: "10.115.8.40",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "169 - PM43 - DCG #I7 (p169-s3-160)",
    address: "10.115.39.219",
    server: "PD 2198",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "170 - PM43 - DCG #I7 (p170-s1-170)",
    address: "10.115.38.182",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "171 - PM43 - DCG #J5 (p171-s1-171)",
    address: "10.115.39.2",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "172 - PM43 - DCG #J6 (p172-s2-160)",
    address: "10.115.39.188",
    server: "PD 2197",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "173 - PM43 - DCG #J7 (p173-s1-173)",
    address: "10.115.39.216",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "174 - PM43 - DCG #K3 (p174-s1-174)",
    address: "10.115.38.69",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "175 - PM43 - DCG #K7 (p175-s1-175)",
    address: "10.115.39.86",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "176 - PM43 - DCG #K8 (p176-s3-172)",
    address: "10.115.38.198",
    server: "PD 2198",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "177 - PM43 - DCG #G2 (p177-s1-177)",
    address: "10.115.38.151",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "178 - PM43 - DCG #I2 (p178-s1-178)",
    address: "10.115.8.39",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "179 - PM43 - DCG #I2 (p179-s3-195)",
    address: "10.115.8.34",
    server: "PD 2198",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "180 - PM43 - DCG #L5 (p180-s1-180)",
    address: "10.115.39.36",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "181 - PM43 - DCG #L7 (p181-s1-181)",
    address: "10.115.8.41",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "182 - PM43 -DCG WH (p182-s1-182)",
    address: "10.236.32.23",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "182 - PM43 DCG King Pack (p182-s4-22)",
    address: "10.236.32.23",
    server: "NP 2185",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "183 - PM43 - DCG WH (p183-s1-183)",
    address: "10.236.32.20",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "184 - PM43 - DCG WH (p184-s2-161)",
    address: "10.236.32.22",
    server: "PD 2197",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "185 - PM43 - DCG Backup (p185-s1-185)",
    address: "10.115.39.19",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "185 - PM43 Terry Gulledge (p185-s4-23)",
    address: "10.115.39.19",
    server: "NP 2185",
    group: "Default",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "186 - PM43 - DCG Backup (p186-s1-186)",
    address: "10.115.39.22",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "186 - PM43 - DCG Backup (p186-s4-14)",
    address: "10.115.39.22",
    server: "NP 2185",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "187 - PM43 - DCG Paint (p187-s1-187)",
    address: "10.115.8.101",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "188 - PM43 - DCG RonK (p188-s1-188)",
    address: "10.115.8.100",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "189 - PM43 - DCG #F8 (p189-s1-189)",
    address: "10.115.8.25",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "190 - PM43 - DCG #C6 (p190-s1-190)",
    address: "10.115.8.105",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "191 - PM43 - DCG #G7 (p191-s1-191)",
    address: "10.115.38.223",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "192 - PM43 - Yale Berlin Knob (p192-s2-162)",
    address: "10.203.40.39",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "193 - PC43T - Corbin Berlin (p193-s1-193)",
    address: "10.203.40.176",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PC41 (IPL)",
  },
  {
    description: "194 - PC43T - Yale Berlin (p194-s1-194)",
    address: "10.203.30.13",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PC41 (IPL)",
  },
  {
    description: "195 - PM4i - Yale Berlin (p195-s1-195)",
    address: "10.203.0.165",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "196 - PM4i - Yale Berlin (p196-s3-35)",
    address: "NUL",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "196 - PM4i - Yale Berlin AV (p196-s5-61)",
    address: "10.203.2.26",
    server: "PD BLN7 *ACTIVE*",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "197 - PM43 - Yale Berlin (p197-s2-163)",
    address: "10.203.4.5",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "198 - PM43 - Yale Berlin (p198-s1-198)",
    address: "10.203.1.50",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "199 - PC43T - Yale Berlin (p199-s3-38)",
    address: "10.203.30.55",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PC41 (IPL)",
  },
  {
    description: "200 - PC43T - Yale Berlin (p200-s1-200)",
    address: "10.203.30.53",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PC41 (IPL)",
  },
  {
    description: "201 - PM4i - Yale Berlin (p201-s1-201)",
    address: "10.203.0.162",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "202 - PC43T - Yale Berlin (p202-s2-164)",
    address: "10.203.41.15",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PC41 (IPL)",
  },
  {
    description: "203 - PM43 - Yale Berlin (p203-s1-203)",
    address: "10.203.39.100",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "204 - PM43 - Yale Berlin (p204-s1-204)",
    address: "10.203.1.62",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "205 - PM4i - Yale Berlin (p205-s1-205)",
    address: "10.203.1.17",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "206 - PM43 - Yale Berlin (p206-s1-206)",
    address: "10.203.39.101",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "207 - PM4i - RWOODCA (p207-s1-207)",
    address: "10.100.6.183",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "208 - PM4i - RWOODCA (p208-s1-208)",
    address: "10.100.6.186",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "209 - PB32 - Einar Test Printer (p209-s4-20)",
    address: "10.236.113.109",
    server: "NP 2185",
    group: "Default",
    driver: "Intermec EasyCoder PM4i(FP version)",
  },
  {
    description: "209 - PB32 - Phillips Mexico (p209-s1-209)",
    address: "10.236.113.109",
    server: "PD 2200",
    group: "Mexico",
    driver: "Intermec EasyCoder PM4i(FP version)",
  },
  {
    description: "210 - PM4i - DCG #C3 (p210-s1-210)",
    address: "10.115.38.208",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "211 - PM43 - PMKV (p211-s1-250)",
    address: "10.100.8.55",
    server: "PD 2200",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "212 - PM43 - DCG #J7 (p212-s3-192)",
    address: "10.115.38.219",
    server: "PD 2198",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "213 - PM43 - DCG #J7 (p213-s1-213)",
    address: "10.115.38.68",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "214 - PM43 - DCG #A3 (p214-s1-214)",
    address: "10.115.39.76",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "215 - PM43 - DCG WH (p215-s1-215)",
    address: "10.236.32.21",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "216 - PM43 - PMKV (p216-s1-216)",
    address: "10.100.8.57",
    server: "PD 2200",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "217 - PM43 - PMKM (p217-s1-245)",
    address: "10.100.24.150",
    server: "PD 2200",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "218 - PM43 - PMKM (p218-s1-246)",
    address: "10.100.24.151",
    server: "PD 2200",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "2185-21 DCG UL Printer (p2185-21-s4-21)",
    address: "10.115.8.50",
    server: "NP 2185",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(FP version)",
  },
  {
    description: "219 - PM43 - PMKV (p219-s2-165)",
    address: "10.100.8.41",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "2198-72 AVAILABLE (p2198-72-s3-72)",
    address: "NUL",
    server: "PD 2198",
    group: "Default",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "220 - PM43 - PMKM (p220-s3-40)",
    address: "10.100.24.152",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "2200-224 - AVAILABLE (p2200-224-s1-224)",
    address: "NUL",
    server: "PD 2200",
    group: "Default",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "2200-225 - AVAILABLE (p2200-225-s1-225)",
    address: "NUL",
    server: "PD 2200",
    group: "Default",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "2200-236 - AVAILABLE (p2200-236-s1-236)",
    address: "NUL",
    server: "PD 2200",
    group: "Default",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "2200-239 - AVAILABLE (p2200-239-s1-239)",
    address: "NUL",
    server: "PD 2200",
    group: "Default",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "2200-240 - AVAILABLE (p2200-240-s1-240)",
    address: "NUL",
    server: "PD 2200",
    group: "Default",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "2200-247 - AVAILABLE (p2200-247-s1-247)",
    address: "NUL",
    server: "PD 2200",
    group: "Default",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "221 - PM43 - PMKM (p221-s3-41)",
    address: "10.100.24.153",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "222 - PM43 - PMKV (p222-s1-222)",
    address: "10.100.8.46",
    server: "PD 2200",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "223 - PM43 - PMKM (p223-s3-42)",
    address: "10.100.24.154",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "224 - PM43 - PMKM (p224-s1-248)",
    address: "10.100.24.155",
    server: "PD 2200",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "225 - PM43 - PMKM (p225-s1-249)",
    address: "10.100.24.156",
    server: "PD 2200",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "226 - PM43 - PMKM (p226-s1-226)",
    address: "10.100.24.157",
    server: "PD 2200",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "227 - PM43 - PMKM (p227-s3-43)",
    address: "10.100.24.158",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "228 - PM43 - PMKM (p228-s3-44)",
    address: "10.100.24.159",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "229 - PM43 - PMKM (p229-s1-244)",
    address: "10.100.24.160",
    server: "PD 2200",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "230 - PM43 - PMKM (p230-s2-166)",
    address: "10.100.24.161",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "231 - PM43 - PMKM (p231-s3-79)",
    address: "10.100.24.162",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "232 - PM43 - PMKM (p232-s3-80)",
    address: "10.100.24.248",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "233 - PM43 - PMKV (p233-s3-81)",
    address: "10.100.8.48",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "234 - PM43 - PMKV (p234-s3-82)",
    address: "10.100.8.44",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "234 - PM43 Ken Gums Test Printer (p234-s4-24)",
    address: "10.100.8.44",
    server: "NP 2185",
    group: "Default",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "235 - PM43 - Berlin (p235-s1-235)",
    address: "10.203.30.8",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "235 - PM43 - PMKV (p235-s3-83)",
    address: "10.100.8.49",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "236 - PM43 - PMKV (p236-s3-84)",
    address: "10.100.8.90",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "237 - PM43 - PMKV (p237-s3-85)",
    address: "10.100.8.91",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "237 - PM43 - PMKV (p237-s4-5)",
    address: "10.100.8.91",
    server: "NP 2185",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "238 - PM43 - PMKV (p238-s3-86)",
    address: "10.100.8.92",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "239 - PM43 - PMKV (p239-s3-45)",
    address: "10.100.8.93",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "240 - PM43 - PMKV (p240-s3-88)",
    address: "10.100.8.94",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "241 - PM43 - PMKV (p241-s3-89)",
    address: "10.100.8.95",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "241 - PM43 - PMKV (p241-s4-6)",
    address: "10.100.8.95",
    server: "NP 2185",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "242 - PM43 - PMKV (p242-s3-90)",
    address: "10.100.8.98",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "243 - PM43 - RWOOD (p243-s3-46)",
    address: "10.245.79.107",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "244 - PM43 - Rockwood (p244-s2-199)",
    address: "10.245.79.108",
    server: "PD 2197",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "245 - PM43 - Rockwood (p245-s2-200)",
    address: "10.245.79.109",
    server: "PD 2197",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "246 - PM43 - PMKM (p246-s3-92)",
    address: "10.100.24.149",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "247 - PM43 - Sargent (p247-s2-167)",
    address: "10.90.32.69",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "248 - PM43 - Sargent (p248-s3-94)",
    address: "10.90.70.80",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "249 - PM43 - Sargent (p249-s2-168)",
    address: "10.90.70.81",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "250 - PM43 - Sargent (p250-s3-96)",
    address: "10.90.24.45",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "251 - PM43 - Sargent (p251-s3-97)",
    address: "10.90.70.84",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "252 - PM43 - Sargent (p252-s3-98)",
    address: "10.90.74.197",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "253 - PM43 - Sargent (p253-s3-99)",
    address: "10.90.73.65",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "254 - PM43 - Sargent (p254-s3-100)",
    address: "10.90.72.228",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "255 - PM43 - Sargent (p255-s3-101)",
    address: "10.90.70.28",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "256 - PM43 - Sargent (p256-s3-102)",
    address: "10.90.70.85",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "257 - PM43 - Sargent (p257-s3-103)",
    address: "10.90.73.70",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "258 - PM43 - Sargent (p258-s3-104)",
    address: "10.90.69.158",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "259 - PM43 - Sargent (p259-s3-105)",
    address: "10.90.73.127",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "260 - PM43 - Sargent (p260-s3-106)",
    address: "10.90.69.161",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "261 - PM43 - Sargent (p261-s2-201)",
    address: "10.90.74.142",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "262 - PM43 - Sargent (p262-s2-202)",
    address: "10.90.69.163",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "263 - PM43 - Sargent (p263-s3-47)",
    address: "10.90.72.138",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "264 - PM43 - Sargent (p264-s2-204)",
    address: "10.90.72.178",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "265 - PM43 - Sargent (p265-s2-205)",
    address: "10.90.69.166",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "266 - PM43 - Sargent (p266-s2-206)",
    address: "10.90.73.188",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "267 - PM43 - Sargent (p267-s2-207)",
    address: "10.90.70.88",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "268 - PM43 - Sargent (p268-s3-48)",
    address: "10.90.70.89",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "269 - PM43 - Sargent (p269-s2-209)",
    address: "10.90.72.254",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "270 - PM43 - Sargent (p270-s2-210)",
    address: "10.90.72.131",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "271 - PM43 - Sargent (p271-s2-211)",
    address: "10.90.70.92",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "272 - PM43 - Sargent (p272-s2-212)",
    address: "10.90.70.93",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "273 - PM43 - Sargent (p273-s2-213)",
    address: "10.90.27.42",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "274 - PM43 - Sargent (p274-s3-49)",
    address: "10.90.73.148",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "275 - PM43 - Sargent (p275-s2-169)",
    address: "10.90.74.193",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "276 - PM43 - Sargent (p276-s2-216)",
    address: "10.90.73.104",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "277 - PM43 - Sargent (p277-s2-170)",
    address: "10.90.69.150",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "278 - PM43 - Sargent (p278-s2-218)",
    address: "10.90.27.29",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "279 - PM43 - Sargent (p279-s2-171)",
    address: "10.90.24.52",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "280 - PM43 - Sargent (p280-s2-220)",
    address: "10.90.70.98",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "281 - PM43 - Sargent (p281-s2-172)",
    address: "10.90.72.201",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "282 - PM43 - Sargent (p282-s3-50)",
    address: "10.90.73.136",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "283 - PM43 - Sargent (p283-s2-223)",
    address: "10.90.24.37",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "284 - PM43 - Sargent (p284-s2-224)",
    address: "10.90.69.162",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "285 - PM43 - Sargent (p285-s2-173)",
    address: "10.90.72.133",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "286 - PM43 - Sargent (p286-s2-226)",
    address: "10.90.73.137",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "287 - PM43 - Sargent (p287-s2-227)",
    address: "10.90.73.205",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "288 - PM43 - Sargent (p288-s2-228)",
    address: "10.90.27.17",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "289 - PM43 - Sargent (p289-s2-229)",
    address: "10.90.73.140",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "290 - PM43 - Sargent (p290-s2-230)",
    address: "10.90.27.43",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "291 - PM43 - Sargent (p291-s2-231)",
    address: "10.90.72.130",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "292 - PM43 - Sargent (p292-s2-232)",
    address: "10.90.27.19",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "293 - PM43 - Sargent (p293-s2-233)",
    address: "10.90.72.129",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "294 - PM43 - Sargent (p294-s2-234)",
    address: "10.90.73.85",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "295 - PM43 - Sargent (p295-s2-235)",
    address: "10.90.74.160",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "296 - PM43 - Sargent (p296-s2-236)",
    address: "10.90.70.87",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "297 - PM43 - Sargent (p297-s2-237)",
    address: "10.90.69.155",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "298 - PM43 - Sargent (p298-s2-238)",
    address: "10.90.39.11",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "299 - PM43 - Sargent (p299-s2-174)",
    address: "10.90.32.4",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "300 - PM43 - Sargent (p300-s3-51)",
    address: "10.90.24.26",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "301 - PM43 - Sargent (p301-s3-107)",
    address: "10.90.75.207",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "302 - PM43 - Sargent (p302-s3-108)",
    address: "10.90.70.106",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "303 - PM43 - Sargent (p303-s3-109)",
    address: "10.90.70.107",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "304 - PM43 - Sargent (p304-s3-110)",
    address: "10.90.27.28",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "305 - PM43 - Sargent (p305-s3-111)",
    address: "10.90.27.30",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "306 - PM43 - Sargent (p306-s3-112)",
    address: "10.90.70.108",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "307 - PM43 - Sargent (p307-s3-113)",
    address: "10.90.73.22",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "308 - PM43 - Sargent (p308-s3-114)",
    address: "10.90.71.20",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "309 - PM43 - Sargent (p309-s3-115)",
    address: "10.90.71.21",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "310 - PM43 - Sargent (p310-s3-116)",
    address: "10.90.73.206",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "311 - PM43 - Sargent (p311-s3-117)",
    address: "10.90.74.216",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "312 - PM43 - Sargent (p312-s3-118)",
    address: "10.90.72.144",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "313 - PM43 - Sargent (p313-s2-175)",
    address: "10.90.73.113",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "314 - PM43 - Sargent (p314-s3-120)",
    address: "10.90.73.123",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "315 - PM43 - Sargent (p315-s3-121)",
    address: "10.90.71.23",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "316 - PM43 - Sargent (p316-s3-122)",
    address: "10.90.72.93",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "317 - PM43 - Sargent (p317-s2-176)",
    address: "10.90.72.66",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "318 - PM43 - Sargent (p318-s3-124)",
    address: "10.90.72.166",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "319 - PM43 - Sargent (p319-s3-52)",
    address: "10.90.72.8",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "320 - PM43 - Sargent (p320-s3-53)",
    address: "10.90.74.194",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "321 - PM43 - Sargent (p321-s3-127)",
    address: "10.90.73.211",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "322 - PM43 - Sargent (p322-s3-128)",
    address: "10.90.72.139",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "323 - PM43 - Sargent (p323-s2-177)",
    address: "10.90.73.55",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "324 - PM43 - Sargent (p324-s3-130)",
    address: "10.90.74.17",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "325 - PM43 - Sargent (p325-s3-131)",
    address: "10.90.74.18",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "326 - PM43 - Sargent (p326-s3-132)",
    address: "10.90.74.201",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "327 - PM43 - Sargent (p327-s2-178)",
    address: "10.90.72.126",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "328 - PM43 - Sargent (p328-s3-134)",
    address: "10.90.74.35",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "329 - PM43 - Sargent (p329-s3-54)",
    address: "10.90.74.38",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "330 - PM43 - Sargent (p330-s2-179)",
    address: "10.90.72.206",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "331 - PM43 - Sargent (p331-s3-137)",
    address: "10.90.36.93",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "332 - PM43 - McKinney (p332-s3-138)",
    address: "10.239.45.4",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "333 - Z420d - Jerith (p333-s3-139)",
    address: "10.236.96.50",
    server: "PD 2198",
    group: "Ameristar",
    driver: "Zebra ZPLII",
  },
  {
    description: "334 - PB32 - Phillips Mexico (p334-s3-55)",
    address: "10.236.102.150",
    server: "PD 2198",
    group: "Mexico",
    driver: "Intermec EasyCoder PM4i(FP version)",
  },
  {
    description: "335 - Z420d - Jerith (p335-s3-141)",
    address: "10.236.96.52",
    server: "PD 2198",
    group: "Ameristar",
    driver: "Zebra ZPLII",
  },
  {
    description: "336 - Z420d - Jerith (p336-s3-56)",
    address: "10.236.96.54",
    server: "PD 2198",
    group: "Ameristar",
    driver: "Zebra ZPLII",
  },
  {
    description: "337 - PM43 - Berlin (p337-s3-143)",
    address: "10.203.40.105",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "338 - PM43 - PMKV (p338-s2-180)",
    address: "10.100.8.42",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "339 - PM43 - PMKV (p339-s2-181)",
    address: "10.100.8.43",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "340 - PM43 - PMKV (p340-s3-57)",
    address: "10.100.8.97",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "341 - PM43 - PMKV (p341-s2-182)",
    address: "10.100.8.160",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "342 - PM43 - PMKV (p342-s1-119)",
    address: "10.100.8.161",
    server: "PD 2200",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "343 - Z420d - Jerith (p343-s1-122)",
    address: "10.236.96.55",
    server: "PD 2200",
    group: "Ameristar",
    driver: "Zebra ZPLII",
  },
  {
    description: "344 - Z420d - Jerith (p344-s1-166)",
    address: "10.236.96.58",
    server: "PD 2200",
    group: "Ameristar",
    driver: "Zebra ZPLII",
  },
  {
    description: "345 - PB32 - Mexico (p345-s1-230)",
    address: "10.236.102.187",
    server: "PD 2200",
    group: "Mexico",
    driver: "Intermec EasyCoder PM4i(FP version)",
  },
  {
    description: "346 - Z420d - Jerith (p346-s1-231)",
    address: "10.236.96.62",
    server: "PD 2200",
    group: "Ameristar",
    driver: "Zebra ZPLII",
  },
  {
    description: "347 - PB32 - Mexico (p347-s3-146)",
    address: "10.236.102.189",
    server: "PD 2198",
    group: "Mexico",
    driver: "Intermec EasyCoder PM4i(FP version)",
  },
  {
    description: "348 - Z420d - Jerith (p348-s3-147)",
    address: "10.236.96.64",
    server: "PD 2198",
    group: "Ameristar",
    driver: "Zebra ZPLII",
  },
  {
    description: "349 - PB32 - Mexico (p349-s3-145)",
    address: "10.236.102.190",
    server: "PD 2198",
    group: "Mexico",
    driver: "Intermec EasyCoder PM4i(FP version)",
  },
  {
    description: "350 - Z420d - Jerith (p350-s3-144)",
    address: "10.236.96.67",
    server: "PD 2198",
    group: "Ameristar",
    driver: "Zebra ZPLII",
  },
  {
    description: "351 - Z420d - Jerith (Test) (p351-s3-142)",
    address: "10.236.96.68",
    server: "PD 2198",
    group: "Ameristar",
    driver: "Zebra ZPLII",
  },
  {
    description: "352 - PM43 - McKinney (p352-s3-140)",
    address: "10.203.30.4",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "353 - PB32 - Mexico (p353-s3-136)",
    address: "10.236.102.194",
    server: "PD 2198",
    group: "Mexico",
    driver: "Intermec EasyCoder PM4i(FP version)",
  },
  {
    description: "354 - PB32 - Phillips Mexico (p354-s3-58)",
    address: "10.236.102.195",
    server: "PD 2198",
    group: "Mexico",
    driver: "Intermec EasyCoder PM4i(FP version)",
  },
  {
    description: "355 - PM43 - Sargent (p355-s3-135)",
    address: "10.90.24.125",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "356 - PB32 - Mexico (p356-s3-133)",
    address: "10.236.113.105",
    server: "PD 2198",
    group: "Mexico",
    driver: "Intermec EasyCoder PM4i(FP version)",
  },
  {
    description: "357 - PM43 - Sargent (p357-s3-129)",
    address: "10.90.32.57",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "358 - PB32 - Mexico (p358-s3-126)",
    address: "10.236.102.199",
    server: "PD 2198",
    group: "Mexico",
    driver: "Intermec EasyCoder PM4i(FP version)",
  },
  {
    description: "359 - PM43 - Corbin Berlin (p359-s3-125)",
    address: "10.203.30.1",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "360 - PB32 - Phillips Mexico (p360-s2-183)",
    address: "10.236.102.117",
    server: "PD 2197",
    group: "Mexico",
    driver: "Intermec EasyCoder PM4i(FP version)",
  },
  {
    description: "361 - PM43 - Corbin Berlin (p361-s3-123)",
    address: "10.203.30.2",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "362 - S4M - Mexico (p362-s3-119)",
    address: "10.236.100.53",
    server: "PD 2198",
    group: "Mexico",
    driver: "Zebra S4M (IPL)",
  },
  {
    description: "363 - PM43 - Medeco (p363-s2-240)",
    address: "10.129.34.200",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "364 - PM43 - Medeco (p364-s2-239)",
    address: "10.129.34.151",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "365 - PM43 - Medeco (p365-s2-225)",
    address: "10.129.34.128",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "366 - PM43 - PMKV (p366-s2-222)",
    address: "10.100.8.113",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "367 - PM43 - PMKV (p367-s3-59)",
    address: "10.100.8.114",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "368 - PM43 - PMKV (p368-s2-221)",
    address: "10.100.8.115",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "369 - PM43 - PMKV (p369-s2-219)",
    address: "10.100.8.116",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "370 - PM43 - PMKV (p370-s2-217)",
    address: "10.100.8.118",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "371 - PM43 - PMKV (p371-s2-184)",
    address: "10.100.8.162",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "372 - PM43 - PMKV (p372-s2-185)",
    address: "10.100.8.121",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "373 - PM43 - PMKV (p373-s3-60)",
    address: "10.100.8.122",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "374 - PM43 - PMKV (p374-s2-215)",
    address: "10.100.8.123",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "375 - PM43 - PMKM (p375-s2-186)",
    address: "10.100.24.211",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "376 - PM43 - PMKM (p376-s2-214)",
    address: "10.100.24.213",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "377 - PM43 - PMKM (p377-s2-208)",
    address: "10.100.24.216",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "378 - PM43 - PMKM (p378-s3-61)",
    address: "10.100.24.219",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "379 - PM43 - PMKM (p379-s2-187)",
    address: "10.100.24.220",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "380 - PM43 - PMKM (p380-s2-203)",
    address: "10.100.24.221",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "381 - PM45 - PMKM (p381-s3-95)",
    address: "10.100.24.36",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "382 - PM43 - PMKM (p382-s3-93)",
    address: "10.100.24.226",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "383 - PM43 - PMKV (p383-s3-91)",
    address: "10.100.8.127",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "384 - PM43 - PMKV (p384-s3-87)",
    address: "10.100.8.128",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "385 - PM43 - PMKM (p385-s3-78)",
    address: "10.100.24.241",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "386 - PM43 - Berlin (p386-s1-228)",
    address: "10.203.41.80",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "387 - PM43 - Berlin (p387-s1-227)",
    address: "10.203.41.152",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "388 - PM43 - PMKM (p388-s1-223)",
    address: "10.100.24.244",
    server: "PD 2200",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "389 - PM43 - PMKM (p389-s3-62)",
    address: "10.100.24.245",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "390 - PM43 - PMKM (p390-s3-63)",
    address: "10.100.24.246",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "391 - PM43 - Corbin Berlin (p391-s2-188)",
    address: "10.203.30.51",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "392 - PM4i - Corbin Berlin (p392-s2-189)",
    address: "10.203.1.73",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "392 - PM4i - Corbin Berlin AV (p392-s5-189)",
    address: "10.203.1.73",
    server: "PD BLN7 *ACTIVE*",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "393 - PM43 - Corbin Berlin (p393-s3-64)",
    address: "10.203.1.75",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "394 - PM4i - Berlin (p394-s1-221)",
    address: "10.203.1.76",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "395 - PM4i - Berlin (p395-s1-220)",
    address: "10.203.1.77",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "396 - PM43 - Berlin (p396-s1-219)",
    address: "10.203.2.27",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "397 - PM43 - Berlin (p397-s1-212)",
    address: "10.203.2.28",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "398 - PM43 - Berlin (p398-s1-202)",
    address: "10.203.1.71",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "399 - PM43 - Berlin (p399-s1-199)",
    address: "10.203.40.7",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "400 - PM43 - Corbin Berlin (p400-s3-65)",
    address: "10.203.41.34",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "401 - PM4i - Berlin (p401-s1-197)",
    address: "10.203.1.60",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "402 - PM4i - Berlin (p402-s1-196)",
    address: "10.203.1.10",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "402 - PM4i - Berlin AV (p402-s5-91)",
    address: "10.203.1.10",
    server: "PD BLN7 *ACTIVE*",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "403 - PM43 - Corbin Berlin (p403-s3-66)",
    address: "NUL",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "403 - PM43 - Corbin Berlin AV (p403-s5-92)",
    address: "10.203.1.11",
    server: "PD BLN7 *ACTIVE*",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "404 - PM4i - Berlin (p404-s1-192)",
    address: "10.203.1.253",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "405 - PM4i - Corbin Berlin (p405-s2-190)",
    address: "10.203.1.97",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "406 - PM43 - Corbin Berlin (p406-s3-67)",
    address: "NUL",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "406 - PM43 - Corbin Berlin AV (p406-s5-95)",
    address: "10.203.42.86",
    server: "PD BLN7 *ACTIVE*",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "407 - PM4i - Berlin (p407-s1-184)",
    address: "NUL",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "407 - PM4i - Berlin (p407-s5-96)",
    address: "10.203.1.79",
    server: "PD BLN7 *ACTIVE*",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "408 - PM43 - Corbin Berlin (p408-s3-68)",
    address: "10.203.39.7",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "409 - PM4i - Berlin (p409-s1-179)",
    address: "10.203.1.81",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "410 - PM43A - Berlin (p410-s1-176)",
    address: "10.203.30.52",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "411 - PM4i - Berlin (p411-s1-172)",
    address: "10.239.46.61",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "412 - PM43 - Berlin (p412-s1-169)",
    address: "10.239.44.212",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "413 - PM4i - Berlin (p413-s1-164)",
    address: "10.203.1.82",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "414 - PM43 - Berlin (p414-s1-161)",
    address: "10.203.39.218",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "415 - PM43 - PMKM (p415-s1-160)",
    address: "10.100.24.247",
    server: "PD 2200",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "416 - PM43 - PMKM (p416-s1-159)",
    address: "10.100.24.240",
    server: "PD 2200",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "417 - PM43 - PMKM (p417-s1-156)",
    address: "10.100.24.243",
    server: "PD 2200",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "418 - PB50 - PHYS INV RWD (p418-s1-155)",
    address: "10.245.79.140",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "419 - PM43 - Corbin Berlin (p419-s3-69)",
    address: "10.203.30.3",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "420 - PM43 - DCG #G2 (p420-s3-32)",
    address: "10.115.38.83",
    server: "PD 2198",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "421 - PC43T - RWOOD (p421-s1-153)",
    address: "10.245.79.143",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PC41 (IPL)",
  },
  {
    description: "422 - PM43 - Sargent (p422-s3-71)",
    address: "10.90.73.149",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "423 - PM43 - Sargent (p423-s2-191)",
    address: "10.90.71.157",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "424 - PM43 - Sargent (p424-s1-152)",
    address: "10.90.70.83",
    server: "PD 2200",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "425 - PB50 - RWOOD (p425-s1-151)",
    address: "10.100.8.243",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "426 - PB50 - Berlin (p426-s1-149)",
    address: "10.239.45.212",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "427 - PC43T - RWOOD (p427-s1-145)",
    address: "10.245.79.144",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PC41 (IPL)",
  },
  {
    description: "428 - PM4i - Berlin (p428-s1-142)",
    address: "10.239.44.34",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "429 - PM43 - Berlin (p429-s1-135)",
    address: "10.239.47.101",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "430 - PC43t - Berlin (p430-s1-133)",
    address: "10.203.2.32",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PC41 (IPL)",
  },
  {
    description: "431 - PM4i - Berlin (p431-s1-127)",
    address: "10.203.30.39",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "432 - PM43 - Berlin (p432-s1-126)",
    address: "NUL",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "432 - PM43 - Berlin AV (p432-s5-110)",
    address: "10.203.30.24",
    server: "PD BLN7 *ACTIVE*",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "433 - PM43 - Berlin (p433-s1-125)",
    address: "10.203.30.10",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "434 - PM43 - Corbin Berlin (p434-s2-192)",
    address: "10.203.30.17",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "435 - PM43 - Corbin Berlin (p435-s2-193)",
    address: "10.203.30.11",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "436 - PM43 - Berlin (p436-s1-124)",
    address: "10.203.41.203",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "437 - PM43 - Berlin (p437-s1-123)",
    address: "10.203.39.165",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "438 - PM4i - AACDSS (p438-s2-194)",
    address: "10.140.2.65",
    server: "PD 2197",
    group: "Canada",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "440 - PC43t - Berlin (p440-s1-120)",
    address: "10.203.30.7",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PC41 (IPL)",
  },
  {
    description: "441 - PM43 - Corbin Berlin (p441-s2-67)",
    address: "10.203.41.122",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "442 - PM43 - Medeco (p442-s1-117)",
    address: "10.129.34.200",
    server: "PD 2200",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "443 - PM4i - Berlin (p443-s1-116)",
    address: "10.203.30.18",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "444 - PM4i - Berlin (p444-s1-115)",
    address: "10.203.1.86",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "445 - PM4i - Corbin Berlin (p445-s2-195)",
    address: "10.203.1.100",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "446 - PM43 - Corbin Berlin (p446-s2-196)",
    address: "10.203.4.10",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "447 - PM43 - Corbin Berlin AV (p447-s5-122)",
    address: "10.203.41.120",
    server: "PD BLN7 *ACTIVE*",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "447 - PM43 - Corbin Berlin NA (p447-s2-197)",
    address: "NUL",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "448 - PM43 - Corbin Berlin (p448-s3-154)",
    address: "10.203.30.54",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "449 - PM43 - Corbin Berlin (p449-s3-73)",
    address: "10.203.30.9",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "450 - PM43 - Berlin (p450-s1-113)",
    address: "10.239.45.57",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description:
      "451 - PM43 - Berlin 6/27/2022 11:28 moved to azure (p451-s1-102)",
    address: "10.203.1.74",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "452 - PM43 - RWOOD (p452-s1-96)",
    address: "10.245.79.134",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "453 - PM43 - Berlin (p453-s1-93)",
    address: "10.203.30.34",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "454 - PC41 - Berlin (p454-s1-92)",
    address: "10.203.2.31",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PC41 (IPL)",
  },
  {
    description: "455 - PB32 - PMKM (p455-s1-86)",
    address: "10.100.25.134",
    server: "PD 2200",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "456 - PM43 - Berlin (p456-s1-85)",
    address: "10.239.45.170",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "457 - PM43 - Sargent (p457-s1-83)",
    address: "10.90.73.125",
    server: "PD 2200",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "458 - PM43 - Sargent (p458-s1-82)",
    address: "10.90.74.138",
    server: "PD 2200",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "459 - PM43 - DCG new #J2 (p459-s1-80)",
    address: "10.115.39.4",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "460 - PM4i - RWOOD (p460-s1-76)",
    address: "10.100.6.187",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "461 - PM43 - Sargent (p461-s2-198)",
    address: "10.90.73.43",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "462 - PM43 - Sargent (p462-s1-74)",
    address: "10.90.0.3",
    server: "PD 2200",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "463 - PM43 - Sargent (p463-s1-71)",
    address: "10.90.40.24",
    server: "PD 2200",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "464 - PM43 - SGT (p464-s1-67)",
    address: "10.90.74.170",
    server: "PD 2200",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "465 - PM43 - Sargent (p465-s1-57)",
    address: "10.90.74.171",
    server: "PD 2200",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "466 - PM43 - Sargent (p466-s1-47)",
    address: "10.90.73.54",
    server: "PD 2200",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "467 - PB32 - DCG (p467-s1-46)",
    address: "10.115.39.80",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "468 - PM43 - Medeco New (p468-s1-42)",
    address: "10.129.34.82",
    server: "PD 2200",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "471 - PM43 - Sargent (p471-s2-245)",
    address: "10.90.75.232",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "472 - PM43 - Sargent (p472-s2-246)",
    address: "10.90.75.236",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "473 - PM43 - Sargent (p473-s2-247)",
    address: "10.90.73.232",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "474 - PM43 - Sargent (p474-s2-248)",
    address: "10.90.73.245",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "475 - PM43 - Sargent (p475-s2-249)",
    address: "10.90.73.105",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "476 - PM43 - Sargent (p476-s2-250)",
    address: "10.90.73.121",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "477 - PM43 - Sargent (p477-s2-251)",
    address: "10.90.73.106",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "478 - PM43 - Sargent (p478-s2-252)",
    address: "10.90.73.108",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "479 - PM43 - Sargent (p479-s2-253)",
    address: "10.90.75.217",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "480 - PM43 - Sargent (p480-s2-254)",
    address: "10.90.75.233",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "481 - PM43 - Sargent (p481-s2-255)",
    address: "10.90.73.177",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "482 - PM43 - Sargent (p482-s2-256)",
    address: "10.90.73.167",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "483 - PM43 - Sargent (p483-s2-257)",
    address: "10.90.72.109",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "484 - PM43 - Sargent (p484-s2-259)",
    address: "10.90.74.167",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "485 - PM43 - Sargent (p485-s2-260)",
    address: "10.90.36.10",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "486 - SATO - Sargent (p486-s2-261)",
    address: "10.90.75.38",
    server: "PD 2197",
    group: "Sargent",
    driver: "SATO IGL",
  },
  {
    description: "487 - PM43 - Sargent (p487-s2-262)",
    address: "10.90.73.191",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "488 - PM43 - Sargent (p488-s1-211)",
    address: "10.90.74.133",
    server: "PD 2200",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "489 - PM43 - Sargent (p489-s1-217)",
    address: "10.90.73.133",
    server: "PD 2200",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "491 - PM43 - Yale Berlin 7000b (p491-s3-74)",
    address: "10.203.30.16",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "492 - PB32 - PHYS INV TEMP PMK (p492-s1-41)",
    address: "10.115.37.128",
    server: "PD 2200",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "493 - PM43 - Berlin (p493-s1-30)",
    address: "10.203.40.152",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "494 - PM43 - Berlin (p494-s1-29)",
    address: "10.239.47.103",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "495 - PC43T - Berlin (p495-s1-8)",
    address: "10.203.41.254",
    server: "PD 2200",
    group: "Berlin",
    driver: "Intermec EasyCoder PC41 (IPL)",
  },
  {
    description: "501 - PM43 - Lorient (RKW500) (p501-s2-1)",
    address: "10.155.4.155",
    server: "PD 2197",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "502 - PM4i - Assa Medeco (p502-s2-2)",
    address: "10.129.34.95",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "503 - PM4i - Medeco US (p503-s2-3)",
    address: "10.129.34.99",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "504 - PM4i - Medeco US (p504-s2-4)",
    address: "10.129.34.61",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "505 - PM4i - Medeco US (p505-s2-5)",
    address: "10.129.34.108",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "506 - PM4i - Medeco US (p506-s2-6)",
    address: "10.129.34.177",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "507 - PM4i - Medeco US (p507-s2-7)",
    address: "10.129.34.176",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "508 - PM4i - Medeco US (p508-s2-8)",
    address: "10.129.34.151",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "509 - PM4i - Medeco US (p509-s2-9)",
    address: "10.129.34.127",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "510 - PM4i - Medeco US (p510-s2-10)",
    address: "10.129.34.128",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "511 - PM4i - Medeco US (p511-s2-11)",
    address: "10.129.34.129",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "512 - PM4i - Medeco US (p512-s2-12)",
    address: "10.129.34.103",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "513 - PM4i - Medeco US (p513-s2-13)",
    address: "10.129.34.106",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "514 - PM4i - Medeco US (p514-s2-14)",
    address: "10.129.34.107",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "515 - PM4i - Medeco US (p515-s2-15)",
    address: "10.129.34.109",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "516 - PM4i - Medeco US (p516-s2-16)",
    address: "10.129.34.110",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "516 - PM4i - Medeco US (s2-16) (p516-s4-26)",
    address: "10.129.34.110",
    server: "NP 2185",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "517 - PM4i - Medeco US (p517-s2-17)",
    address: "10.129.34.111",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "518 - PM4i - Medeco US (p518-s2-18)",
    address: "10.129.34.112",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "519 - PM4i - Medeco US (p519-s2-19)",
    address: "10.129.34.113",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "520 - PM4i - Medeco US (p520-s2-20)",
    address: "10.129.34.114",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "521 - PM4i - Medeco US (p521-s2-21)",
    address: "10.129.34.115",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "522 - PM4i - Medeco US (p522-s2-22)",
    address: "10.129.34.116",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "523 - PM4i - Medeco US (p523-s2-23)",
    address: "10.129.34.117",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "524 - PM4i - Medeco US (p524-s2-24)",
    address: "10.129.34.118",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "525 - PM4i - Medeco US (p525-s2-25)",
    address: "10.129.34.119",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "526 - PM4i - Medeco US (p526-s2-26)",
    address: "10.129.34.120",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "527 - PM4i - Medeco US (p527-s2-27)",
    address: "10.129.34.121",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "528 - PM4i - Medeco US (p528-s2-28)",
    address: "10.129.34.122",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "529 - PM4i - Medeco US (p529-s2-29)",
    address: "10.129.34.123",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "530 - PM4i - Medeco US (p530-s2-30)",
    address: "10.129.34.124",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "531 - PM4i - Medeco US (p531-s2-31)",
    address: "10.129.34.125",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "532 - PM4i - Medeco US (p532-s2-32)",
    address: "10.129.34.126",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "533 - PM4i - Medeco US (p533-s2-33)",
    address: "10.129.34.181",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "534 - PM43 - AACDSS (p534-s2-34)",
    address: "10.140.2.61",
    server: "PD 2197",
    group: "Canada",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "535 - PM4i - Arrow Express (p535-s2-35)",
    address: "10.203.0.77",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "536 - 105SL - AmeriStar (p536-s2-36)",
    address: "10.239.137.166",
    server: "PD 2197",
    group: "Ameristar",
    driver: "Zebra 105SL",
  },
  {
    description: "537 - PC43 - EMSO DISABLED (Bartender) (p537-s2-37)",
    address: "NUL",
    server: "PD 2197",
    group: "EMSO",
    driver: "Intermec EasyCoder PD41 (IPL)",
  },
  {
    description: "538 - PC43 - EMSO DSE (p538-s2-38)",
    address: "10.246.29.218",
    server: "PD 2197",
    group: "EMSO",
    driver: "Intermec IPL",
  },
  {
    description: "539 - PB50 - PMKM (p539-s2-39)",
    address: "10.100.25.150",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "540 - PB50 - RWOOD (p540-s2-40)",
    address: "10.239.45.225",
    server: "PD 2197",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "541 - PB50 - RWOOD (p541-s2-41)",
    address: "10.239.45.194",
    server: "PD 2197",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "542 - PB50 - RWOOD (p542-s2-42)",
    address: "10.239.44.134",
    server: "PD 2197",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "543 - PM43 - Berlin (p543-s2-43)",
    address: "10.239.47.105",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "544 - PM43 - Berlin (p544-s2-44)",
    address: "10.203.30.33",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "545 - PC43T - Berlin (p545-s2-45)",
    address: "10.239.46.107",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PC41 (IPL)",
  },
  {
    description: "546 - PM43 - Berlin (p546-s2-46)",
    address: "10.203.45.170",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "547 - PB32 - TEMP PHYS INV PMK (p547-s2-47)",
    address: "10.115.108.56",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "548 - PB32 - TEMP PHYS INV PMK (p548-s2-48)",
    address: "10.100.9.51",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "549 - PB32 - TEMP PHYS INV PMK (p549-s2-49)",
    address: "10.115.108.61",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "550 - PB32 - TEMP PHYS INV PMK (p550-s2-50)",
    address: "10.115.108.57",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "551 - PB32 - TEMP PHYS INV PMK (p551-s2-51)",
    address: "10.100.9.54",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "552 - PB32 - TEMP PHYS INV PMK (p552-s2-52)",
    address: "10.100.9.55",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "553 - PB32 - TEMP PHYS INV PMK (p553-s2-53)",
    address: "10.115.108.52",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "555 - PB32 - TEMP PHYS INV PMK (p555-s2-55)",
    address: "10.115.108.58",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "556 - PB32 - TEMP PHYS INV PMK (p556-s2-56)",
    address: "10.115.108.60",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "557 - PB32 - TEMP PHYS INV PMK (p557-s2-57)",
    address: "10.100.9.59",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "558 - PB32 - TEMP PHYS INV PMK (p558-s2-58)",
    address: "10.115.38.165",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "559 - PB32 - TEMP PHYS INV PMK (p559-s2-59)",
    address: "10.100.9.61",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "560 - PB32 - TEMP PHYS INV PMK (p560-s2-60)",
    address: "10.115.108.54",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "561 - PB50 - PMKV (p561-s2-61)",
    address: "10.100.9.64",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "562 - PB50 - TEMP PHYS INV PMK (p562-s2-62)",
    address: "10.100.25.200",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "563 - PB32 - TEMP PHYS INV PMK (p563-s2-63)",
    address: "10.115.108.53",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "564 - PM43 - AACDSS (p564-s2-64)",
    address: "10.140.2.68",
    server: "PD 2197",
    group: "Canada",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "565 - PC43T - Sargent Dom Viscuso (p565-s2-65)",
    address: "10.90.17.13",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PC41 (IPL)",
  },
  {
    description: "566 - Weber - Ameristar (p566-s2-66)",
    address: "10.239.136.204",
    server: "PD 2197",
    group: "Ameristar",
    driver: "Zebra 110PAX4",
  },
  {
    description: "567 - 105SL - Ameristar (p567-s2-68)",
    address: "10.239.136.152",
    server: "PD 2197",
    group: "Ameristar",
    driver: "Zebra 105SL",
  },
  {
    description: "568 - 105SL - Ameristar (p568-s2-69)",
    address: "10.239.136.146",
    server: "PD 2197",
    group: "Ameristar",
    driver: "Zebra 105SL",
  },
  {
    description: "569 - 105SL - Ameristar (p569-s2-70)",
    address: "10.239.136.154",
    server: "PD 2197",
    group: "Ameristar",
    driver: "Zebra 105SL",
  },
  {
    description: "570 - 105SL - Ameristar (p570-s2-71)",
    address: "10.239.136.151",
    server: "PD 2197",
    group: "Ameristar",
    driver: "Zebra 105SL",
  },
  {
    description: "571 - 105SL - Ameristar (p571-s2-72)",
    address: "10.239.136.153",
    server: "PD 2197",
    group: "Ameristar",
    driver: "Zebra 105SL",
  },
  {
    description: "572 - 105SL - Ameristar (p572-s2-73)",
    address: "10.239.136.144",
    server: "PD 2197",
    group: "Ameristar",
    driver: "Zebra 105SL",
  },
  {
    description: "573 - PM43 - Medeco (p573-s2-74)",
    address: "10.129.34.23",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "574 - 105SL - Ameristar (p574-s2-75)",
    address: "10.239.136.161",
    server: "PD 2197",
    group: "Ameristar",
    driver: "Zebra 105SL",
  },
  {
    description: "575 - PM43 - Somerset (p575-s2-76)",
    address: "10.155.4.8",
    server: "PD 2197",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "576 - PM43 - Somerset (p576-s2-77)",
    address: "10.155.4.9",
    server: "PD 2197",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "577 - PM43 - Medeco (p577-s2-78)",
    address: "10.129.34.55",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "578 - PB32 - TEMP PHYS INV PMK (p578-s2-79)",
    address: "10.115.108.51",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "579 - PB32 - TEMP PHYS INV PMK (p579-s2-80)",
    address: "10.115.108.45",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "580 - PB32 - TEMP PHYS INV PMK (p580-s2-81)",
    address: "10.115.108.48",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "581 - PB32 - TEMP PHYS INV PMK (p581-s2-82)",
    address: "10.115.108.47",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "582 - PB32 - PMKM (p582-s2-83)",
    address: "10.115.38.149",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "583 - PB32 - TEMP PHYS INV PMK (p583-s2-84)",
    address: "10.100.25.205",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "584 - PB32 - TEMP PHYS INV PMK (p584-s2-85)",
    address: "10.115.108.42",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "585 - PB32 - TEMP PHYS INV PMK (p585-s2-86)",
    address: "10.115.108.49",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "586 - PB32 - TEMP PHYS INV PMK (p586-s2-87)",
    address: "10.115.108.46",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "587 - PB32 - TEMP PHYS INV PMK (p587-s2-88)",
    address: "10.115.108.44",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "588 - PB32 - TEMP PHYS INV PMK (p588-s2-89)",
    address: "10.115.108.43",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "589 - PB32 - TEMP PHYS INV PMK (p589-s2-90)",
    address: "10.115.108.40",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "590 - PB32 - TEMP PHYS INV PMK (p590-s2-91)",
    address: "10.115.108.2",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "591 - PB32 - TEMP PHYS INV PMK (p591-s2-92)",
    address: "10.115.108.28",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "592 - PB32 - TEMP PHYS INV PMK (p592-s2-93)",
    address: "10.115.108.25",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "593 - PM43 - Medeco (p593-s2-94)",
    address: "10.129.34.72",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "594 - PM43 - Medeco (p594-s2-95)",
    address: "10.129.34.71",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "595 - PB32 - TEMP PHYS INV PMK (p595-s2-96)",
    address: "10.115.108.14",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "596 - PB32 - DCG (p596-s2-97)",
    address: "10.115.108.15",
    server: "PD 2197",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "597 - PB32 - TEMP PHYS INV PMK (p597-s2-98)",
    address: "10.115.108.27",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "598 - PB32 - PMKV (p598-s2-99)",
    address: "10.115.108.16",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "599 - PB32 - TEMP PHYS INV PMK (p599-s2-100)",
    address: "10.115.108.3",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "600 - PB32 - TEMP PHYS INV PMK (p600-s2-101)",
    address: "10.115.108.31",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "601 - PB32 - TEMP PHYS INV PMK (p601-s2-102)",
    address: "10.115.108.19",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "602 - PB32 - TEMP PHYS INV PMK (p602-s2-103)",
    address: "10.115.39.153",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "603 - PB32 - TEMP PHYS INV PMK (p603-s2-104)",
    address: "10.115.108.12",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "604 - PB32 - TEMP PHYS INV PMK (p604-s2-105)",
    address: "10.115.108.7",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "605 - PB32 - DCG (p605-s2-106)",
    address: "10.115.38.67",
    server: "PD 2197",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "606 - PB32 - TEMP PHYS INV PMK (p606-s2-107)",
    address: "10.115.108.17",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "607 - PB32 - TEMP PHYS INV PMK (p607-s2-108)",
    address: "10.115.108.34",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "608 - PB32 - TEMP PHYS INV PMK (p608-s2-109)",
    address: "10.115.38.196",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "609 - PB32 - TEMP PHYS INV PMK (p609-s2-110)",
    address: "10.115.108.26",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "610 - PM43 - Medeco (p610-s2-111)",
    address: "10.129.34.63",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "611 - PM43 - Medeco (p611-s2-112)",
    address: "10.129.34.65",
    server: "PD 2197",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "612 - ZT500 - Ameristar ECOAT3 (p612-s1-218)",
    address: "10.239.136.122",
    server: "PD 2200",
    group: "Ameristar",
    driver: "Zebra 105SL",
  },
  {
    description: "612 - ZT500 - Ameristar ECOAT3 (p612-s4-12)",
    address: "10.239.136.122",
    server: "NP 2185",
    group: "Ameristar",
    driver: "Zebra 105SL",
  },
  {
    description: "613 - S4M - Curries (p613-s2-115)",
    address: "10.26.36.95",
    server: "PD 2197",
    group: "DoorAndFlash",
    driver: "Zebra S4M (ZPL)",
  },
  {
    description: "614 - ZE500 - Ameristar (p614-s2-116)",
    address: "10.239.136.111",
    server: "PD 2197",
    group: "Ameristar",
    driver: "Zebra ZPLII",
  },
  {
    description: "615 - PM43 - Medeco (p615-s3-1)",
    address: "10.129.34.66",
    server: "PD 2198",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "615 - PM43 - Medeco (p615-s4-3)",
    address: "10.129.34.66",
    server: "NP 2185",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "616 - PB50 - TEMP PHYS INV PMK (p616-s2-117)",
    address: "10.115.37.151",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "617 - PM43 - Yale Berlin (p617-s3-36)",
    address: "10.203.6.3",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "618 - PM43 - Yale Berlin (p618-s3-37)",
    address: "10.203.30.6",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "619 - PB50 - PHYS INV RWD (p619-s2-118)",
    address: "10.245.79.170",
    server: "PD 2197",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "620 - PB50 - RWOOD (p620-s2-119)",
    address: "10.239.45.224",
    server: "PD 2197",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "621 - PB50 - RWOOD (p621-s2-120)",
    address: "10.245.79.172",
    server: "PD 2197",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "622 - PM43 - YALER (p622-s3-75)",
    address: "10.203.6.1",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "623 - PM43 - YALER (p623-s3-76)",
    address: "10.203.6.2",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "624 - PB32 - PMKM (p624-s2-121)",
    address: "10.115.108.22",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "625 - PB32 - TEMP PHYS INV PMK (p625-s2-122)",
    address: "10.115.108.50",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "626 - PB50 - TEMP PHYS INV PMK (p626-s2-123)",
    address: "10.115.108.23",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "627 - PB32 - TEMP PHYS INV PMK (p627-s2-124)",
    address: "10.100.25.213",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "628 - PM43 - QuickShip FL (p628-s2-125)",
    address: "10.239.150.25",
    server: "PD 2197",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "629 - PM43 - QuickShip FL (p629-s2-126)",
    address: "10.239.150.26",
    server: "PD 2197",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "630 - PB50 - TEMP PHYS INV PMK (p630-s2-127)",
    address: "10.115.108.20",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "631 - PB50 - TEMP PHYS INV PMK (p631-s2-128)",
    address: "10.100.25.225",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "632 - PB32 - TEMP PHYS INV PMK (p632-s2-129)",
    address: "10.115.108.41",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "633 - PB50 - TEMP PHYS INV PMK (p633-s2-130)",
    address: "10.115.108.30",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "634 - PB50 - TEMP PHYS INV PMK (p634-s2-131)",
    address: "10.115.38.104",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "635 - PB50 - TEMP PHYS INV PMK (p635-s2-132)",
    address: "10.115.108.32",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "637 - PB50 - DCG (p637-s2-134)",
    address: "10.115.39.80",
    server: "PD 2197",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "638 - PB50 - DCG (p638-s2-135)",
    address: "10.115.39.81",
    server: "PD 2197",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "639 - PB50 - DCG (p639-s2-136)",
    address: "10.115.38.67",
    server: "PD 2197",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "641 - PB50 - DCG (p641-s2-138)",
    address: "10.115.39.85",
    server: "PD 2197",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "641 - PB50 - DCG (p641-s4-7)",
    address: "10.115.39.85",
    server: "NP 2185",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "642 - PB32 - TEMP PHYS INV PMK (p642-s2-139)",
    address: "10.115.108.29",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "643 - PB32 - TEMP PHYS INV PMK (p643-s2-140)",
    address: "10.100.25.215",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "644 - PB32 - TEMP PHYS INV PMK (p644-s2-141)",
    address: "10.100.25.216",
    server: "PD 2197",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "645 - PM43 - Medeco (p645-s3-77)",
    address: "10.129.34.51",
    server: "PD 2198",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "646 - PM43 - Berlin (p646-s3-148)",
    address: "10.203.4.2",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "647 - PM43 - Medeco (p647-s3-149)",
    address: "10.129.34.76",
    server: "PD 2198",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "648 - PM43 - Medeco (p648-s3-150)",
    address: "10.129.34.215",
    server: "PD 2198",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "649 - PM43 - Medeco (p649-s3-151)",
    address: "10.129.34.2",
    server: "PD 2198",
    group: "Medeco",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "650 - YALE FLASH KENT (p650-s3-152)",
    address: "10.249.240.93",
    server: "PD 2198",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "651 - FLASHship Kent (p651-s4-25)",
    address: "10.249.240.94",
    server: "NP 2185",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "651 - YALE FLASH KENT (p651-s3-153)",
    address: "10.249.240.94",
    server: "PD 2198",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "652 - DCG Rixson JoyChief (p652-s3-70)",
    address: "10.236.32.24",
    server: "PD 2198",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "653 - PM43 - Rockwood (p653-s3-155)",
    address: "10.155.4.27",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "654 - PM43 - Rockwood (p654-s3-156)",
    address: "10.155.4.28",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "655 - PM43 - Rockwood (p655-s3-157)",
    address: "10.245.79.156",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "656 - PM43 - Rockwood (p656-s3-158)",
    address: "10.245.79.157",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "657 - PC43t - EMSO DSC (p657-s3-159)",
    address: "10.246.29.19",
    server: "PD 2198",
    group: "EMSO",
    driver: "Intermec IPL",
  },
  {
    description: "658 - PM43 - Sargent Cyl (p658-s2-241)",
    address: "10.90.73.183",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "659 - PC43t - EMSO DSA (p659-s3-161)",
    address: "10.246.29.72",
    server: "PD 2198",
    group: "EMSO",
    driver: "Intermec IPL",
  },
  {
    description: "660 - PC43t - EMSO DSB (p660-s3-162)",
    address: "10.246.29.82",
    server: "PD 2198",
    group: "EMSO",
    driver: "Intermec IPL",
  },
  {
    description: "661 - PC43t - EMSO DSD (p661-s3-163)",
    address: "10.246.29.74",
    server: "PD 2198",
    group: "EMSO",
    driver: "Intermec IPL",
  },
  {
    description: "662 - PC43t - EMSO DSE (p662-s4-2)",
    address: "10.246.29.85",
    server: "NP 2185",
    group: "EMSO",
    driver: "Intermec IPL",
  },
  {
    description: "662 - PC43t - EMSO TEST (p662-s3-164)",
    address: "10.246.29.85",
    server: "PD 2198",
    group: "EMSO",
    driver: "Intermec IPL",
  },
  {
    description: "663 - PC43t - EMSO DSF (p663-s3-165)",
    address: "10.246.29.70",
    server: "PD 2198",
    group: "EMSO",
    driver: "Intermec IPL",
  },
  {
    description: "664 - PM43 - Rockwood (p664-s3-166)",
    address: "10.155.4.165",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "665 - PM43 - Rockwood (p665-s3-167)",
    address: "10.155.4.166",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "666 - PM43 - Rockwood (p666-s3-168)",
    address: "10.155.4.167",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "667 - PM43 - Rockwood (p667-s3-169)",
    address: "10.155.4.168",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "668 - PM43 - Sargent (p668-s3-170)",
    address: "10.90.74.150",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "669 - PM43 - Sargent (p669-s3-171)",
    address: "10.90.74.151",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "671 - Rockwood (p671-s3-173)",
    address: "10.245.79.181",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "672 - Rockwood (p672-s3-174)",
    address: "10.245.79.182",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "673 - PB50 - PHYS INV RWD (p673-s3-175)",
    address: "10.245.79.183",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "674 - PB50 - PHYS INV RWD (p674-s3-176)",
    address: "10.245.79.184",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "675 - PB50 - PHYS INV RWD (p675-s3-177)",
    address: "10.100.8.243",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "676 - PB50 - PHYS INV RWD (p676-s3-178)",
    address: "10.245.79.186",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "677 - Rockwood (p677-s3-179)",
    address: "10.155.4.150",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "678 - Rockwood (p678-s3-180)",
    address: "10.155.4.151",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "679 - Rockwood (p679-s3-181)",
    address: "10.155.4.152",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "680 - Rockwood (p680-s3-182)",
    address: "10.155.4.153",
    server: "PD 2198",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "681 - Berlin Exit (p681-s3-183)",
    address: "10.203.39.206",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "682 - Berlin Bored (p682-s3-184)",
    address: "10.203.39.211",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "683 - Berlin Yale (p683-s3-185)",
    address: "10.203.11.1",
    server: "PD 2198",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "684 - TEMP PHYS INV PMK (p684-s3-186)",
    address: "10.100.8.105",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "685 - TEMP PHYS INV PMK (p685-s3-187)",
    address: "10.100.25.236",
    server: "PD 2198",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "686 - ZT510 - Ameristar ECOAT2 (p686-s3-194)",
    address: "10.239.137.28",
    server: "PD 2198",
    group: "Ameristar",
    driver: "Zebra 105SL",
  },
  {
    description: "687 - FLASHship FL (p687-s3-189)",
    address: "10.239.150.27",
    server: "PD 2198",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "688 - FLASHship FL (p688-s3-190)",
    address: "10.239.150.28",
    server: "PD 2198",
    group: "DoorAndFlash",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "689 - PM43 - Sargent (p689-s3-191)",
    address: "10.90.72.132",
    server: "PD 2198",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "690 - QLN320 - Assa Mexico (p690-s2-114)",
    address: "10.236.102.137",
    server: "PD 2197",
    group: "Mexico",
    driver: "Zebra ZPLII",
  },
  {
    description: "690 - QLN320 Mexico (p690-s4-1)",
    address: "10.236.102.137",
    server: "NP 2185",
    group: "Mexico",
    driver: "Zebra ZPLII",
  },
  {
    description: "691 - QLN320 - Assa Mexico (p691-s2-133)",
    address: "10.236.102.157",
    server: "PD 2197",
    group: "Mexico",
    driver: "Zebra ZPLII",
  },
  {
    description: "692 - QLN320 - Assa Mexico (p692-s2-137)",
    address: "10.236.102.162",
    server: "PD 2197",
    group: "Mexico",
    driver: "Zebra ZPLII",
  },
  {
    description: "692 - QLN320 - Assa Mexico (p692-s4-29)",
    address: "10.236.102.162",
    server: "NP 2185",
    group: "Mexico",
    driver: "Zebra ZPLII",
  },
  {
    description: "693 - QLN320 - Assa Mexico (p693-s2-242)",
    address: "10.236.102.153",
    server: "PD 2197",
    group: "Mexico",
    driver: "Zebra ZPLII",
  },
  {
    description: "694 - QLN320 - Assa Mexico (p694-s2-243)",
    address: "10.236.102.156",
    server: "PD 2197",
    group: "Mexico",
    driver: "Zebra ZPLII",
  },
  {
    description: "695 - QLN320 - Assa Mexico (p695-s2-244)",
    address: "10.236.113.120",
    server: "PD 2197",
    group: "Mexico",
    driver: "Zebra ZPLII",
  },
  {
    description: "696 - PM43 - Lorient KY (p696-s1-232)",
    address: "10.155.4.157",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "697 - PM43 - Lorient KY (p697-s1-233)",
    address: "10.155.4.156",
    server: "PD 2200",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "698 - PM43 - DCG (p698-s1-234)",
    address: "10.115.37.119",
    server: "PD 2200",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "699 - PM43 - Sargent - TEMP (p699-s2-258)",
    address: "10.90.73.223",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "700 - PM43 - Lorient (RKW500) (p700-s2-263)",
    address: "10.34.159.43",
    server: "PD 2197",
    group: "Rockwood",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "701 - RP4D - Assa Mexico (p701-s2-264)",
    address: "10.236.102.201",
    server: "PD 2197",
    group: "Mexico",
    driver: "Zebra ZPLII",
  },
  {
    description: "702 - RP4D - Assa Mexico (p702-s2-265)",
    address: "10.236.102.202",
    server: "PD 2197",
    group: "Mexico",
    driver: "Zebra ZPLII",
  },
  {
    description: "703 - RP4D - Assa Mexico (p703-s2-266)",
    address: "10.236.102.204",
    server: "PD 2197",
    group: "Mexico",
    driver: "Zebra ZPLII",
  },
  {
    description: "704 - RP4D - Assa Mexico (p704-s2-267)",
    address: "10.236.102.205",
    server: "PD 2197",
    group: "Mexico",
    driver: "Zebra ZPLII",
  },
  {
    description: "705 - PM43 - Corbin & Yale Berlin (p705-s2-268)",
    address: "10.203.41.201",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "706 - PM43 - Sargent (p706-s2-269)",
    address: "10.90.73.218",
    server: "PD 2197",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "707 - PM43 - FLASHship Elkridge MD (p707-s2-270)",
    address: "10.17.24.50",
    server: "PD 2197",
    group: "Default",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "708 - PM43 - FLASHship Elkridge MD (p708-s2-271)",
    address: "10.17.24.51",
    server: "PD 2197",
    group: "Default",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "709 - PM43 - YALER (p709-s2-145)",
    address: "10.203.30.14",
    server: "PD 2197",
    group: "Berlin",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "711 - PM43 - Sargent (p711-s1-237)",
    address: "10.90.74.175",
    server: "PD 2200",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "712 - PM43 - Sargent (p712-s1-238)",
    address: "10.90.74.176",
    server: "PD 2200",
    group: "Sargent",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "713 - PM43 - PMKM (p713-s1-229)",
    address: "10.100.24.79",
    server: "PD 2200",
    group: "Pemko",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "714 - ZT230 - Bruken (p714-s1-241)",
    address: "10.103.44.100",
    server: "PD 2200",
    group: "Default",
    driver: "Zebra ZPLII",
  },
  {
    description: "714 - ZT230 - Bruken (p714-s4-27)",
    address: "NUL",
    server: "NP 2185",
    group: "Default",
    driver: "Zebra ZPLII",
  },
  {
    description: "715 - ZT230 - Bruken (p715-s1-242)",
    address: "10.103.44.102",
    server: "PD 2200",
    group: "Default",
    driver: "Zebra ZPLII",
  },
  {
    description: "715 - ZT230 - Bruken (p715-s4-28)",
    address: "NUL",
    server: "NP 2185",
    group: "Default",
    driver: "Zebra ZPLII",
  },
  {
    description: "716 - ZT230 - Bruken (p716-s1-243)",
    address: "10.103.44.103",
    server: "PD 2200",
    group: "Default",
    driver: "Zebra ZPLII",
  },
  {
    description: "717 - PB32 - DCG (p717-s3-11)",
    address: "10.115.38.166",
    server: "PD 2198",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "718 - PB32 - DCG (p718-s3-12)",
    address: "10.115.38.169",
    server: "PD 2198",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "719 - PB32 - DCG (p719-s3-13)",
    address: "10.115.38.170",
    server: "PD 2198",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "720 - PB32 - DCG (p720-s3-14)",
    address: "10.115.38.177",
    server: "PD 2198",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "721 - PB32 - DCG (p721-s3-15)",
    address: "10.115.37.59",
    server: "PD 2198",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description:
      "DONOTUSE-OLD-686 - Ameristar ECOAT2 (pDONOTUSE-OLD-686-s3-188)",
    address: "NUL",
    server: "PD 2198",
    group: "Ameristar",
    driver: "Zebra 105SL",
  },
  {
    description: "TEST DCG (pTEST-s3-193)",
    address: "10.115.37.54",
    server: "PD 2198",
    group: "DCG",
    driver: "Intermec EasyCoder PM4i(IPL version)",
  },
  {
    description: "Zebra 110Xi4 (300 dpi) Print To File (pZebra-s4-30)",
    address: "FILE",
    server: "NP 2185",
    group: "Default",
    driver: "Zebra 110Xi4 (300 dpi) Print To File",
  },
];
