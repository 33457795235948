import React from "react";
import { IonItem, IonGrid, IonCol, IonRow, IonLabel } from "@ionic/react";
import { PoaDetailsPoLineModel } from "../models/PoaDetails";

interface PoaDetailsPoLineProps {
  poLine: PoaDetailsPoLineModel;
  // onClickDetail: (poLineData: PoaDetailsPoLineModel) => void;
  //onClickDetail: MouseEventHandler;
  //using Function here instead of () => void raises a "function type is not assignable to type MouseEventHandler"
  onClickDetail: any;
}

const PoaDetailsPoLine: React.FC<PoaDetailsPoLineProps> = (
  props
): JSX.Element => {
  return (
    <IonItem detail={true} onClick={() => props.onClickDetail(props.poLine)}>
      <IonLabel>
        <IonGrid>
          <strong>{props.poLine.description}</strong>
          <IonRow>
            <IonCol>
              Item Number
              <br />
              Total
              <br />
              Unit Price
              <br />
              Order Quantity
            </IonCol>
            <IonCol className="ion-text-right">
              {props.poLine.itemNumber}
              <br />
              {props.poLine.extendedCost} {props.poLine.currency}
              <br />
              {props.poLine.unitCost} {props.poLine.currency}
              <br />
              {props.poLine.quantityOrdered} {props.poLine.uom}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonLabel>
    </IonItem>
  );
};

export default PoaDetailsPoLine;
