import React, { useState } from "react";
import {
  IonContent,
  IonPage,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
} from "@ionic/react";
import "./Login.scss";
import {
  setIsAuthenticatedAzure,
  setIsAuthenticatedOrch,
  setIsLoggedIn,
  setUsername,
  setEncodedCreds,
  setUserAddress,
} from "../data/user/user.actions";
import { azureConfigBuild } from "../azureConfigBuild";
import { azureConfigDev } from "../azureConfigDev";
import { azureConfigProd } from "../azureConfigProd";
import { connect } from "../data/connect";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { PublicClientApplication } from "@azure/msal-browser";
import { Redirect } from "react-router";
import { RouteComponentProps } from "react-router";
import is from "date-fns/esm/locale/is/index.js";
// import { is } from "date-fns/locale";

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  isAuthenticatedAzure: string;
  isAuthenticatedOrch: string;
}

interface DispatchProps {
  setIsAuthenticatedAzure: typeof setIsAuthenticatedAzure;
  setIsAuthenticatedOrch: typeof setIsAuthenticatedOrch;
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
  setEncodedCreds: typeof setEncodedCreds;
  setUserAddress: typeof setUserAddress;
}

interface LoginProps extends OwnProps, StateProps, DispatchProps {}

const Login: React.FC<LoginProps> = ({
  isAuthenticatedAzure,
  isAuthenticatedOrch,
  setIsLoggedIn: setIsLoggedInAction,
  history,
  setUsername: setUsernameAction,
  setEncodedCreds: setEncodedCredsAction,
  setUserAddress: setUserAddressAction,
  setIsAuthenticatedAzure: setIsAuthenticatedAzureAction,
  setIsAuthenticatedOrch: setIsAuthenticatedOrchAction,
}) => {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [userIdError, setUserIdError] = useState(false);
  const [userIdErrorMatch, setUserIdErrorMatch] = useState(false);
  const [orchError, setOrchError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const url =
    "https://jdedvorch.assaabloy.net/jderest/orchestrator/JDE_ORCH_55_GetUserNumber";
  let bodyData: object = {};
  let azadUserEmail: string | null = "";

  // if (isAuthenticatedAzure == "1" && isAuthenticatedOrch == "1") {
  if (isAuthenticatedOrch === "1") {
    return <Redirect to="/home" />;
  }

  let azureConfig: any = undefined;

  if (window.location.host === "localhost:3000") {
    azureConfig = azureConfigBuild;
  } else if (window.location.host === "localhost:8100") {
    azureConfig = azureConfigDev;
  } else {
    //prod
    azureConfig = azureConfigProd;
  }

  const publicClientApplication: any = new PublicClientApplication({
    auth: {
      clientId: azureConfig.appId,
      redirectUri: azureConfig.redirectUri,
      authority: azureConfig.authority,
    },
    cache: {
      cacheLocation: "sessionStorage",
      //storeAuthStateInCookies: true
    },
  });

  async function azureLogin() {
    try {
      // Login via popup
      const response = await publicClientApplication.loginPopup({
        scopes: azureConfig.scopes,
        prompt: "select_account",
      });
      console.log(JSON.stringify(response));
      // console.log(JSON.stringify(response.account));
      if (response.account.homeAccountId) {
        const sessAzureAdKey = response.account.homeAccountId;
        console.log(sessAzureAdKey);
        azadUserEmail = response.account.username;
        console.log(azadUserEmail);
        if (azadUserEmail) {
          // await setPageUsername(azadUserEmail);
          await setUsernameAction(azadUserEmail);
          await setIsAuthenticatedAzureAction("1");
        }
        const azadUserFullName: string | null = response.account.name;
        console.log(azadUserFullName);
        // const azadName: string | null = sessionStorage.getItem(sessAzureAdKey)
      }
      //setState({isAuthentivated:true})
    } catch (err) {
      // setState({
      //   isAuthenticated: false,
      //   user: {},
      //   error: err
      // });
    }
  }

  async function fetchUser(
    url: string = "",
    bodyData: object = {},
    encodedCreds: string = ""
  ) {
    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + encodedCreds,
        },
        body: JSON.stringify(bodyData),
      });
      if (response.ok) {
        return response.json();
      } else {
        console.log(response.status);
        console.log(response.statusText);
        //403 Forbidden if creds wrong
      }
    } catch (error) {
      //console.log('ERROR:', err.message);
      //Failed to fetch //wrong url
      //wrong url also returns net::ERR_NAME_NOT_RESOLVED (but I have not figured out how to catch that)
      console.error(error);
      //wrong url returns net::ERR_NAME_NOT_RESOLVED and this error is undefined
    }
  }

  const login = async (e: React.FormEvent) => {
    e.preventDefault();
    setOrchError(false);
    setFormSubmitted(true);
    if (!userId) {
      setUserIdError(true);
      return;
    }
    setUserIdError(false);
    if (!password) {
      setPasswordError(true);
      return;
    }
    setPasswordError(false);
    // await azureLogin();
    //console.log("immediately after call azureLogin"); //9/15/2022: confirmed this runs *after* azureLogin returns -- good.
    // console.log("pageUsername: " + pageUsername);
    // console.log(
    //   "azadUserEmail in login function after azureLogin function returns: " +
    //     azadUserEmail
    // );
    //20220929 use userId in encodedCreds -- now that we are prompting for user ID -- instead of email because this fixed Hitesh's issue with multiple email IDs.
    const loginEncodedCreds = btoa(userId + ":" + password);
    console.log("loginEncodedCreds: " + loginEncodedCreds);
    let userAddressNumber: string = "";
    setEncodedCredsAction(loginEncodedCreds);
    bodyData = {
      "User ID 1": userId,
    };
    fetchUser(url, bodyData, loginEncodedCreds).then((loadedData) => {
      console.log("login loadedData: " + JSON.stringify(loadedData));
      if (
        // !loadedData.ServiceRequest2.fs_DATABROWSE_F0092.data.gridData.summary.records
        !loadedData
        //9/15/2022 RA: this should be reliable, because invalid username will not have this data structure and it will fail. And a successful call will return numeric 1 (== True).
      ) {
        console.log("get user api failed");
        // console.log(JSON.stringify(loadedData)); //undefined
        setOrchError(true);
        setPassword("");
        return;
      }
      const userIdOrch =
        loadedData.ServiceRequest1.fs_DATABROWSE_F0092L.data.gridData.rowset[0]
          .F0092L_USER;
      if (userIdOrch.toUpperCase() != userId.toUpperCase()) {
        console.log(
          "entered userid (" +
            userId.toUpperCase() +
            ") does not match userid returned by orch (" +
            userIdOrch.toUpperCase() +
            ")"
        );
        setUserIdErrorMatch(true);
        return;
      }
      setIsAuthenticatedOrchAction("1");
      userAddressNumber =
        loadedData.ServiceRequest2.fs_DATABROWSE_F0092.data.gridData.rowset[0]
          .F0092_AN8;
      console.log("userAddressNumber: " + userAddressNumber);
      setUserAddressAction(userAddressNumber);
      setIsLoggedInAction(true);
      // console.log(btoa(username + ":" + password));
      // history.push("/tabs/schedule", { direction: "none" });
      console.log("before history push");
      console.log("isAuthenticedAzure: " + isAuthenticatedAzure);
      console.log("isAuthenticedOrch: " + isAuthenticatedOrch);
      history.push("/home", { direction: "none" });
    });
  };

  return (
    <IonPage id="login-page">
      <Header
        appTitle="JDE Mobile Apps"
        backButtonHref=""
        backIsShown={false}
      />
      <IonContent className="ion-padding">
        <div>
          <h1 className="ion-text-center">ASSA ABLOY</h1>
        </div>
        <form noValidate onSubmit={login}>
          <IonList>
            <IonItem>
              <IonLabel position="stacked" color="primary">
                JDE User ID
              </IonLabel>
              <IonInput
                name="userId"
                type="text"
                value={userId}
                spellCheck={false}
                autocapitalize="off"
                onIonChange={(e) => setUserId(e.detail.value!)}
                required
              ></IonInput>
            </IonItem>

            {formSubmitted && userIdError && (
              <IonText color="danger">
                <p className="ion-padding-start">User ID is required</p>
              </IonText>
            )}
            {formSubmitted && userIdErrorMatch && (
              <IonText color="danger">
                <p className="ion-padding-start">
                  User ID does not match single sign on credentials
                </p>
              </IonText>
            )}

            <IonItem>
              <IonLabel position="stacked" color="primary">
                Password
              </IonLabel>
              <IonInput
                name="password"
                type="password"
                value={password}
                onIonChange={(e) => setPassword(e.detail.value!)}
              ></IonInput>
            </IonItem>

            {formSubmitted && passwordError && (
              <IonText color="danger">
                <p className="ion-padding-start">
                  Password is required or invalid.
                </p>
              </IonText>
            )}
            {formSubmitted && orchError && (
              <IonText color="danger">
                <p className="ion-padding-start">
                  Login failed. Please try again.
                </p>
              </IonText>
            )}
          </IonList>

          <IonRow>
            <IonCol>
              <IonButton type="submit" expand="block">
                Login
              </IonButton>
            </IonCol>
            {/* <IonCol>
              <IonButton routerLink="/signup" color="light" expand="block">
                Signup
              </IonButton>
            </IonCol> */}
          </IonRow>
        </form>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticatedAzure: state.user.isAuthenticatedAzure,
    isAuthenticatedOrch: state.user.isAuthenticatedOrch,
  }),
  mapDispatchToProps: {
    setIsAuthenticatedAzure,
    setIsAuthenticatedOrch,
    setIsLoggedIn,
    setUsername,
    setEncodedCreds,
    setUserAddress,
  },
  component: Login,
});
