import React from "react";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
} from "@ionic/react";
import SaveButtonFooter from "../components/SaveButtonFooter";
import { PoaDetailsPoLineModel } from "../models/PoaDetails";

interface PoaLineDetailProps {
  poLine: PoaDetailsPoLineModel;
}

const PoaLineDetailPage: React.FC<PoaLineDetailProps> = (
  props
): JSX.Element => {
  return (
    <>
      <IonList>
        <IonItem>
          <IonLabel>
            <strong>Description</strong>
            <br />
            {props.poLine.description}
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <strong>Item Number</strong>
            <br />
            {props.poLine.itemNumber}
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <strong>Total</strong>
            <br />
            {props.poLine.extendedCost} {props.poLine.currency}
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <strong>Order Quantity</strong>
            <br />
            {props.poLine.quantityOrdered} {props.poLine.uom}
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <strong>Unit Price</strong>
            <br />
            {props.poLine.unitCost} {props.poLine.currency}
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <strong>Line Type</strong>
            <br />
            {props.poLine.lineType}
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <strong>Order Date</strong>
            <br />
            {props.poLine.orderDate}
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <strong>Requested Date</strong>
            <br />
            {props.poLine.requestDate}
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <strong>Line Number</strong>
            <br />
            {props.poLine.lineNumber}
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <strong>Remarks</strong>
            <br />
            TODO: need remarks field
          </IonLabel>
        </IonItem>
      </IonList>
      <SaveButtonFooter />
    </>
  );
};

export default PoaLineDetailPage;
