import React from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
} from "@ionic/react";
import { refreshOutline, searchOutline } from "ionicons/icons";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { connect } from "../data/connect";

import { useRef, useState } from "react";
import PriceInquiryType from "../components/PriceInquiryType";

interface StateProps {
  encodedCreds: string;
}

interface DispatchProps {}

interface PriceInquiryProps extends StateProps, DispatchProps {}

const PriceInquiryPage: React.FC<PriceInquiryProps> = ({
  encodedCreds,
}): JSX.Element => {
  const branchInputRef = useRef<HTMLIonInputElement>(null);
  const itemInputRef = useRef<HTMLIonInputElement>(null);
  const customerAddressNumberInputRef = useRef<HTMLIonInputElement>(null);
  const adjustmentScheduleInputRef = useRef<HTMLIonInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [priceData, setPriceData] = useState<any>({});
  const [priceMessage, setPriceMessage] = useState<string>("");
  const [pricingType, setPricingType] = useState<"simple" | "advanced">(
    "simple"
  );
  // const [showPriceToast, setShowPriceToast] = useState<boolean>(false);

  console.log(encodedCreds);

  async function fetchData(
    url: string = "",
    bodyData: object = {},
    encodedCreds: string = ""
  ) {
    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + encodedCreds,
        },
        body: JSON.stringify(bodyData),
      });
      if (response.ok) {
        return response.json();
      } else {
        console.log(response.status);
        console.log(response.statusText);
        //403 Forbidden if creds wrong
      }
    } catch (error) {
      //console.log('ERROR:', err.message);
      //Failed to fetch //wrong url
      //wrong url also returns net::ERR_NAME_NOT_RESOLVED (but I have not figured out how to catch that)
      console.error(error);
      //wrong url returns net::ERR_NAME_NOT_RESOLVED and this error is undefined
    }
  }

  const priceDataBlank: object = {
    branch: "",
    item: "",
    customerAddressNumber: "",
    adjustmentSchedule: "",
    "Unit Price": "",
    "Eff Date Thru": "",
  };

  const money = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  function getPrice(): void {
    let enteredBranch = branchInputRef.current!.value?.toString();
    let enteredItem = itemInputRef.current!.value?.toString();
    let enteredCustomerAddressNumber: string | undefined = "";
    // let enteredAdjustmentSchedule: string | undefined = "";
    if (pricingType === "advanced") {
      enteredCustomerAddressNumber =
        customerAddressNumberInputRef.current!.value?.toString();
      // enteredAdjustmentSchedule =
      //   adjustmentScheduleInputRef.current!.value?.toString();
    }
    let url: string = "";
    let bodyData: object = {};
    console.log("getPrice encoded creds: " + encodedCreds);
    if (!encodedCreds) {
      console.log("encodedCreds false. getPrice canceled");
      setIsLoading(false);
      return;
    }
    setPriceData(priceDataBlank);
    setPriceMessage("");
    setIsLoading(true);

    // setShowPriceToast(false);
    if (!enteredBranch) {
      setPriceMessage("Please enter a branch plant.");
      // setShowPriceToast(true);
      branchInputRef.current?.setFocus();
      setIsLoading(false);
      return;
    }
    if (!enteredItem) {
      setPriceMessage("Please enter an item number.");
      // setShowPriceToast(true);
      itemInputRef.current?.setFocus();
      setIsLoading(false);
      return;
    }
    if (pricingType === "advanced") {
      if (!enteredCustomerAddressNumber) {
        setPriceMessage("Please enter a customer address number.");
        // setShowPriceToast(true);
        customerAddressNumberInputRef.current?.setFocus();
        setIsLoading(false);
        return;
      }
      // if (!enteredAdjustmentSchedule) {
      //   setPriceMessage("Please enter an adjustment schedule.");
      //   // setShowPriceToast(true);
      //   adjustmentScheduleInputRef.current?.setFocus();
      //   setIsLoading(false);
      //   return;
      // }
      // "https://jdedvorch.assaabloy.net/jderest/orchestrator/JDE_ORCH_55_AdvancePricing"; //20220928 - replaced
      // bodyData = {
      //   "Item Number": enteredItem,
      //   "Branch Plant": enteredBranch,
      //   "Address Number": enteredCustomerAddressNumber,
      //   "Adjustment Schedule": enteredAdjustmentSchedule,
      // };
      url =
        "https://jdedvorch.assaabloy.net/jderest/orchestrator/JDE_ORCH_55_CheckPriceAvailability";
      bodyData = {
        Address_Number: enteredCustomerAddressNumber,
        Business_Unit: enteredBranch,
        Item_Number: enteredItem,
      };
    } else {
      url =
        "https://jdedvorch.assaabloy.net/jderest/orchestrator/RG_ORCH_Price_Inquiry";
      bodyData = {
        Branch_Plant: enteredBranch,
        Item_Number: enteredItem,
      };
    }

    console.log("body data: " + bodyData);

    fetchData(url, bodyData, encodedCreds).then((priceResult) => {
      console.log("pr after call: " + JSON.stringify(priceResult));
      if (pricingType === "advanced") {
        // if (!priceResult.ServiceRequest1.fs_P4074_W4074D.data.z_UPRC_44.value) {
        if (!priceResult["Unit Price"]) {
          console.log("advanced fetch failed");
          setIsLoading(false);
          return;
        }
        priceResult.unitPrice = money.format(priceResult["Unit Price"]);
        priceResult.customerAddressNumber = enteredCustomerAddressNumber;
        priceResult.adjustmentSchedule = priceResult["Adjustment Schedule"];
      } else {
        if (!priceResult["Unit Price"]) {
          console.log("fetch failed");
          setIsLoading(false);
          return;
        }
        priceResult.unitPrice = money.format(priceResult["Unit Price"]);
        //console.log(priceResult['Unit Price']);
        console.log(priceResult.unitPrice);

        // priceResult.effDateThru = priceResult["Eff Date Thru"];
        //console.log(priceResult['Eff Date Thru']);
        // console.log(priceResult.effDateThru);
      }
      priceResult.branch = enteredBranch;
      priceResult.item = enteredItem;
      console.log(priceResult);
      //  { Item_Number: "RMB346", Branch_Plant: "PSA100" },
      setPriceData(priceResult);
      setIsLoading(false);
    });
  }

  const resetInput = () => {
    itemInputRef.current!.value = "";
    if (pricingType === "advanced") {
      // adjustmentScheduleInputRef.current!.value = "";
      customerAddressNumberInputRef.current!.value = "";
    }
    itemInputRef.current?.setFocus();
    // setShowPriceToast(false);
    setPriceData(priceDataBlank);
    setPriceMessage("");
    setIsLoading(false);
  };

  const selectPricingTypeHandler = (selectedValue: "simple" | "advanced") => {
    setPricingType(selectedValue);
    setPriceData(priceDataBlank);
    setPriceMessage("");
    setIsLoading(false);
    itemInputRef.current!.value = "";
    itemInputRef.current?.setFocus();
  };

  return (
    <IonPage>
      <Header appTitle="Price Inquiry" backButtonHref="/" backIsShown={true} />
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol>
              <PriceInquiryType
                selectedValue={pricingType}
                onSelectValue={selectPricingTypeHandler}
              ></PriceInquiryType>
            </IonCol>
          </IonRow>
          {priceMessage && (
            <IonRow>
              <IonCol>
                <IonText color="danger">
                  <p className="ion-padding-start">{priceMessage}</p>
                </IonText>
              </IonCol>
            </IonRow>
          )}
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Branch Plant:</IonLabel>
                <IonInput ref={branchInputRef}></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Item Number:</IonLabel>
                <IonInput ref={itemInputRef}></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          {pricingType === "advanced" && (
            <>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel>Customer Address #:</IonLabel>
                    <IonInput ref={customerAddressNumberInputRef}></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
              {/* <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel>Adjustment Sched:</IonLabel>
                    <IonInput ref={adjustmentScheduleInputRef}></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow> */}
            </>
          )}
          <IonRow>
            <IonCol></IonCol>
            <IonCol className="ion-text-left">
              <IonButton expand="block" onClick={getPrice}>
                <IonIcon slot="start" icon={searchOutline}></IonIcon>
                Search
              </IonButton>
            </IonCol>
            <IonCol className="ion-text-right">
              <IonButton expand="block" onClick={resetInput}>
                <IonIcon slot="start" icon={refreshOutline}></IonIcon>
                &nbsp;Reset&nbsp;
              </IonButton>
            </IonCol>
            <IonCol></IonCol>
          </IonRow>
          {isLoading && (
            <IonRow>
              <IonCol className="ion-text-center">
                {/* <IonIcon icon={refreshOutline}></IonIcon> */}
                <IonSpinner />
              </IonCol>
            </IonRow>
          )}
          <IonRow>
            <IonCol>
              <IonItem>
                <p>
                  B/P: {priceData.branch}
                  <br />
                  Item: {priceData.item}
                  <br />
                  {pricingType === "advanced" && (
                    <>
                      Customer Address #: {priceData.customerAddressNumber}
                      <br />
                      Adjustment Sched: {priceData.adjustmentSchedule}
                      <br />
                    </>
                  )}
                  Unit Price: {priceData.unitPrice}
                </p>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isLoggedin,
    encodedCreds: state.user.encodedCreds,
    username: state.user.username,
  }),
  component: PriceInquiryPage,
});
