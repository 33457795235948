import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
} from "@ionic/react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { connect } from "../data/connect";
// import { setUserAddress } from "../data/user/user.actions";
import { refreshOutline } from "ionicons/icons";

interface StateProps {
  encodedCreds: string;
  userAddress: string;
}

interface DispatchProps {}

interface PoaCountsProps extends StateProps, DispatchProps {}

const PoaCountsPage: React.FC<PoaCountsProps> = ({
  encodedCreds,
  userAddress,
}): JSX.Element => {
  console.log("encodedCreds: " + encodedCreds);
  console.log("userAddress: " + userAddress);
  const [purchaseOrderCount, setPurchaseOrderCount] = useState<string>("");
  const [directShipOrderCount, setDirectShipOrderCount] = useState<string>("");
  const [transferOrderCount, setTransferOrderCount] = useState<string>("");
  const [requisitionOrderCount, setRequisitionOrderCount] =
    useState<string>("");
  const [hrefO4, setHrefO4] = useState<string>("/po-approval");
  const [hrefOD, setHrefOD] = useState<string>("/po-approval");
  const [hrefOR, setHrefOR] = useState<string>("/po-approval");
  const [hrefOT, setHrefOT] = useState<string>("/po-approval");

  const url: string =
    "https://jdedvorch.assaabloy.net/jderest/orchestrator/JDE_ORCH_PO_Approval_BP_Filter";

  const fetchData = async (
    url: string = "",
    bodyData: object = {},
    encodedCreds: string,
    orderType: string
  ) => {
    try {
      const loadedData = await fetch(url, {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + encodedCreds,
        },
        body: JSON.stringify(bodyData),
      }).then((response) => response.json());
      console.log(JSON.stringify(loadedData));
      // {
      // if (response.ok) {
      //   console.log(response.status);
      //   console.log(response.statusText);
      //   //403 Forbidden if creds wrong
      // } else {
      //   return response.json();
      // }
      //   return response.json();
      // });
      let poaCount: string = "";
      // console.log(poaCountResult.keys({}).length);
      // if (
      //   //poaCountResult.ServiceRequest1.fs_DATABROWSE_F4209.data.gridData.summary.records
      //   //poaCountResult.keys({}).length > 0 //ts error: keys is not a function
      // ) {
      //   poaCount = String(
      //     poaCountResult.ServiceRequest1.fs_DATABROWSE_F4209.data.gridData
      //       .summary.records
      //   );
      // } else {
      //   poaCount = "Err!";
      // }
      poaCount = String(
        loadedData.ServiceRequest1.fs_DATABROWSE_F4209.data.gridData.summary
          .records
      );
      console.log("order type: " + orderType + ", poaCount: " + poaCount);
      if (orderType === "O4") {
        setPurchaseOrderCount(poaCount);
        if (+poaCount > 0) {
          setHrefO4("/po-approval/list/O4");
        }
      } else if (orderType === "OD") {
        setDirectShipOrderCount(poaCount);
        if (+poaCount > 0) {
          setHrefOD("/po-approval/list/OD");
        }
      } else if (orderType === "OT") {
        setTransferOrderCount(poaCount);
        if (+poaCount > 0) {
          setHrefOT("/po-approval/list/OT");
        }
      } else if (orderType === "OR") {
        setRequisitionOrderCount(poaCount);
        if (+poaCount > 0) {
          setHrefOR("/po-approval/list/OR");
        }
      }
    } catch (error) {
      //console.log('ERROR:', err.message);
      //Failed to fetch //wrong url
      //wrong url also returns net::ERR_NAME_NOT_RESOLVED (but I have not figured out how to catch that)
      console.error(error);
      //wrong url returns net::ERR_NAME_NOT_RESOLVED and this error is undefined
    }
  };

  const getPoaCounts = () => {
    console.log("getPoaCounts encoded creds: " + encodedCreds);
    if (!encodedCreds) {
      console.log("encodedCreds false. getPrice canceled");
      return;
    }
    if (!userAddress) {
      console.log("userAddress false. getPrice canceled");
      return;
    }

    let orderType: string = "";
    const orderTypes: Array<string> = ["O4", "OD", "OT", "OR"];
    for (let i in orderTypes) {
      orderType = orderTypes[i];
      console.log("orderType: " + orderType);
      const bodyData: object = {
        Approver: userAddress, //hitesh: 4252230
        OrTy: orderType,
        AppSts: "2N", //20220706: changed "**" (originator and requestor) to "2N" (requestor only)
      };
      console.log(bodyData);
      fetchData(url, bodyData, encodedCreds, orderType);
    }
  };

  useEffect(() => {
    getPoaCounts();
  }, []);

  // useEffect(() => {
  //   const getPoaCounts = () => {
  //     console.log("getPoaCounts encoded creds: " + encodedCreds);
  //     if (!encodedCreds) {
  //       console.log("encodedCreds false. getPrice canceled");
  //       return;
  //     }

  //     let orderType: string = "";
  //     const orderTypes: Array<string> = ["O4", "OD", "OT", "OR"];
  //     for (let i in orderTypes) {
  //       orderType = orderTypes[i];
  //       console.log("orderType: " + orderType);
  //       const bodyData: object = {
  //         Approver: "4252230", //hitesh: 4252230 //TODO: soft-code
  //         OrTy: orderType,
  //         AppSts: "**",
  //       };

  //       fetchData(url, bodyData, encodedCreds, orderType);
  //     }
  //   };
  //   getPoaCounts();
  // }, []);

  return (
    <IonPage>
      <Header appTitle="PO Approval" backButtonHref="/" backIsShown={true} />
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol>
              {/* <IonCard href="/po-approval/list/O4" color="warning"> */}
              <IonCard href={hrefO4} color="warning">
                <IonCardContent className="ion-text-center">
                  <span style={{ color: "white" }}>
                    {purchaseOrderCount ? purchaseOrderCount : "Loading..."}
                    <br />
                    Purchase Orders (O4)
                  </span>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol>
              {/* <IonCard href="/po-approval/list/0D" color="primary"> */}
              <IonCard href={hrefOD} color="primary">
                <IonCardContent className="ion-text-center">
                  <span style={{ color: "white" }}>
                    {directShipOrderCount ? directShipOrderCount : "Loading..."}
                    <br />
                    Direct Ship Orders (OD)
                  </span>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              {/* <IonCard href="/po-approval/list/OR" color="success"> */}
              <IonCard href={hrefOR} color="success">
                <IonCardContent className="ion-text-center">
                  <span style={{ color: "white" }}>
                    {requisitionOrderCount
                      ? requisitionOrderCount
                      : "Loading..."}
                    <br />
                    Requistion Orders (OR)
                  </span>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol>
              {/* <IonCard href="/po-approval/list/OT" color="danger"> */}
              <IonCard href={hrefOT} color="danger">
                <IonCardContent className="ion-text-center">
                  <span style={{ color: "white" }}>
                    {transferOrderCount ? transferOrderCount : "Loading..."}
                    <br />
                    Transfer Orders (OT)
                  </span>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonIcon slot="start" icon={refreshOutline}></IonIcon>
              <IonButton expand="block" href="/po-approval">
                Refresh
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    encodedCreds: state.user.encodedCreds,
    userAddress: state.user.userAddress,
  }),
  component: PoaCountsPage,
});
