import React from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
} from "@ionic/react";
import { body, refreshOutline, searchOutline } from "ionicons/icons";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { connect } from "../data/connect";
import {
  InventoryInquiryHeaderModel,
  InventoryInquiryDetailModel,
  InventoryInquiryDetailRawModel,
} from "../models/InventoryInquiry";

import { useState } from "react";

interface StateProps {
  encodedCreds: string;
}

interface DispatchProps {}

interface InventoryInquiryProps extends StateProps, DispatchProps {}

const InventoryInquiryPage: React.FC<InventoryInquiryProps> = ({
  encodedCreds,
}): JSX.Element => {
  const inventoryHeaderDataBlank: InventoryInquiryHeaderModel = {
    branchPlant: "",
    itemNumber: "",
    location: "",
    lotNumber: "",
  };

  const inventoryDetailsDataTest: InventoryInquiryDetailModel[] = [
    {
      itemNumber: "85609-000",
      location: "REC",
      lotNumber: "",
      quantity: "200",
    },
    { itemNumber: "M32", location: "STAGE", lotNumber: "", quantity: "5000" },
    {
      itemNumber: "1234567890",
      location: "SHIP",
      lotNumber: "",
      quantity: "90000",
    },
    {
      itemNumber: "M32JNGBQ",
      location: "000A001",
      lotNumber: "",
      quantity: "1",
    },
    {
      itemNumber: "85609-000",
      location: "STOCK",
      lotNumber: "",
      quantity: "200",
    },
    { itemNumber: "M32", location: "01A230", lotNumber: "", quantity: "5000" },
    {
      itemNumber: "1234567890",
      location: "RACK",
      lotNumber: "",
      quantity: "90000",
    },
    {
      itemNumber: "M32JNGBQ",
      location: "CONSIGNMENT",
      lotNumber: "",
      quantity: "1",
    },
  ];

  const [branchPlant, setBranchPlant] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isBranchPlantError, setIsBranchPlantError] = useState<boolean>(false);
  const [isItemLocationError, setIsItemLocationError] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNoInventoryFound, setIsNoInventoryFound] = useState<boolean>(false);
  const [itemNumber, setItemNumber] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [lotNumber, setLotNumber] = useState<string>("");
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [inventoryHeaderData, setInventoryHeaderData] =
    useState<InventoryInquiryHeaderModel>(inventoryHeaderDataBlank);
  const [inventoryDetailsData, setInventoryDetailsData] = useState<
    InventoryInquiryDetailRawModel[]
  >([]);

  const url: string =
    "https://jdedvorch.assaabloy.net/jderest/orchestrator/JDE_ORCH_55_GetInventoryInfo";

  const fetchData = async (
    url: string = "",
    bodyData: object = {},
    encodedCreds: string
  ) => {
    try {
      const loadedData = await fetch(url, {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + encodedCreds,
        },
        body: JSON.stringify(bodyData),
      }).then((response) => response.json());
      console.log(JSON.stringify(loadedData));
      const rows: Array<InventoryInquiryDetailRawModel> =
        loadedData.ServiceRequest1.fs_DATABROWSE_V41021E.data.gridData.rowset;
      if (rows.length > 0) {
        setInventoryDetailsData(rows);
      } else {
        setIsNoInventoryFound(true);
      }
      setIsLoading(false);
    } catch (error) {
      //console.log('ERROR:', err.message);
      //Failed to fetch //wrong url
      //wrong url also returns net::ERR_NAME_NOT_RESOLVED (but I have not figured out how to catch that)
      console.error(error);
      //wrong url returns net::ERR_NAME_NOT_RESOLVED and this error is undefined
    }
  };

  const getInventory = () => {
    setErrorMessage("");
    setInventoryDetailsData([]);
    setIsNoInventoryFound(false);
    console.log("getInventory encodedCreds: " + encodedCreds);
    if (!encodedCreds) {
      console.log("encodedCreds false. getInventory canceled");
      return;
    }
    console.log("b/p: " + branchPlant);
    if (!branchPlant) {
      setErrorMessage("Branch/Plant is required.");
      return;
    }

    if (!itemNumber && !location) {
      setErrorMessage("Item Number or Location is required.");
      return;
    }

    const bodyData: object = {
      BranchPlant: branchPlant,
      ItemNumber: itemNumber,
      Location: location,
      LotNumber: lotNumber,
    };

    console.log("bodyData: " + JSON.stringify(bodyData));
    // const bodyData: object = {
    //   BranchPlant: "EMGM10",
    //   ItemNumber: "M32",
    //   Location: "",
    //   LotNumber: "",
    // };
    setIsLoading(true);

    fetchData(url, bodyData, encodedCreds);

    // console.log(inventoryDetailsDataTest);
    // setInventoryDetailsData(inventoryDetailsDataTest);
    // console.log(inventoryDetailsData.length);
    //
    // fetchData(url, bodyData, encodedCreds).then((inventoryData) => {
    //   // if (!inventoryData) {
    //   //   console.log("fetch failed");
    //   //   return;
    //   // }
    //   // console.log("inventoryData after call: " + inventoryData);
    //   // setInventoryData(inventoryResult);
    // });
  };

  const resetInput = () => {
    setErrorMessage("");
    setInventoryDetailsData([]);
    setIsLoading(false);
    setIsNoInventoryFound(false);
    setItemNumber("");
    setLocation("");
    setLotNumber("");
  };

  return (
    <IonPage>
      <Header
        appTitle="Inventory Inquiry"
        backButtonHref="/"
        backIsShown={true}
      />
      <IonContent className="ion-padding">
        <form noValidate onSubmit={getInventory}>
          {errorMessage && (
            <IonItem>
              <IonCol>
                <IonText color="danger">
                  <p className="ion-padding-start">{errorMessage}</p>
                </IonText>
              </IonCol>
            </IonItem>
          )}
          <IonItem>
            <IonLabel>Branch/Plant:</IonLabel>
            <IonInput
              name="branchPlant"
              type="text"
              value={branchPlant}
              spellCheck={false}
              autocapitalize="off"
              onIonChange={(e) => setBranchPlant(e.detail.value!)}
              required
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Item Number:</IonLabel>
            <IonInput
              name="itemNumber"
              type="text"
              value={itemNumber}
              spellCheck={false}
              autocapitalize="off"
              onIonChange={(e) => setItemNumber(e.detail.value!)}
              required
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Location:</IonLabel>
            <IonInput
              name="location"
              type="text"
              value={location}
              spellCheck={false}
              autocapitalize="off"
              onIonChange={(e) => setLocation(e.detail.value!)}
              required
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Lot Number:</IonLabel>
            <IonInput
              name="lotNumber"
              type="text"
              value={lotNumber}
              spellCheck={false}
              autocapitalize="off"
              onIonChange={(e) => setLotNumber(e.detail.value!)}
              required
            ></IonInput>
          </IonItem>
        </form>
        <IonGrid>
          <IonRow>
            <IonCol></IonCol>
            <IonCol className="ion-text-left">
              <IonButton expand="block" onClick={getInventory}>
                <IonIcon slot="start" icon={searchOutline}></IonIcon>
                Search
              </IonButton>
            </IonCol>
            <IonCol className="ion-text-right">
              <IonButton expand="block" onClick={resetInput}>
                <IonIcon slot="start" icon={refreshOutline}></IonIcon>
                &nbsp;Reset&nbsp;
              </IonButton>
            </IonCol>
            <IonCol></IonCol>
          </IonRow>
          {isLoading && (
            <IonRow>
              <IonCol className="ion-text-center">
                <IonSpinner />
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
        <IonList>
          <IonItem>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <strong>Item Number</strong>
                  <br />
                  Lot Number
                </IonCol>
                <IonCol className="ion-text-right">
                  <strong>
                    <span style={{ color: "blue" }}>Location</span>
                  </strong>
                  <br />
                  Quantity
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
          {isNoInventoryFound && (
            <IonItem>
              <IonGrid>
                <IonRow>
                  <IonCol>No Records Found</IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
          )}
          {inventoryDetailsData.length > 0 &&
            inventoryDetailsData.map((inv) => (
              <IonItem>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <strong>{inv.F4101_LITM}</strong>
                      <br />
                      {inv.F41021_LOTN}
                    </IonCol>
                    <IonCol className="ion-text-right">
                      {inv.F41021_LOCN}
                      <br />
                      {inv.F41021_PQOH}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>
            ))}
        </IonList>
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isLoggedin,
    encodedCreds: state.user.encodedCreds,
  }),
  component: InventoryInquiryPage,
});
