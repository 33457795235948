import React from "react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowBackOutline, refreshOutline } from "ionicons/icons";

interface HeaderProps {
  appTitle: string;
  backButtonHref: string;
  backIsShown: boolean;
}

const Header: React.FC<HeaderProps> = (props): JSX.Element => {
  return (
    <IonHeader>
      <IonToolbar color="primary">
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        {props.backIsShown && (
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={props.backButtonHref}
              icon={arrowBackOutline}
              text=""
            />
          </IonButtons>
        )}
        <IonTitle>{props.appTitle}</IonTitle>
        {/* <IonButtons slot="end">
          <IonButton onClick={window.location.reload}>
            <IonIcon icon={refreshOutline}></IonIcon>
          </IonButton>
        </IonButtons> */}
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
