import React, { useState } from "react";
import {
  IonContent,
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonToast,
} from "@ionic/react";
import "./Login.scss";
import { connect } from "../data/connect";
import Footer from "../components/Footer";
import Header from "../components/Header";

interface OwnProps {}

interface DispatchProps {}

interface SupportProps extends OwnProps, DispatchProps {}

const SupportPage: React.FC<SupportProps> = () => {
  // const [message, setMessage] = useState("");
  // const [formSubmitted, setFormSubmitted] = useState(false);
  // const [messageError, setMessageError] = useState(false);
  const [showToast, setShowToast] = useState(false);

  // const send = (e: React.FormEvent) => {
  //   e.preventDefault();
  //   setFormSubmitted(true);
  //   if (!message) {
  //     setMessageError(true);
  //   }
  //   if (message) {
  //     setMessage("");
  //     setShowToast(true);
  //   }
  // };

  return (
    <IonPage id="support-page">
      <Header
        appTitle="JDE Mobile Apps"
        backButtonHref="/"
        backIsShown={true}
      />
      <IonContent>
        {/* 
      <IonHeader>
      <IonToolbar>
          <IonButtons slot="start">
          <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Support</IonTitle>
        </IonToolbar>
        </IonHeader>
        <div className="login-logo">
        <img src="assets/img/appicon.svg" alt="Ionic logo" />
        </div>
        <IonContent>

        <form noValidate onSubmit={send}>
          <IonList>
            <IonItem>
              <IonLabel position="stacked" color="primary">Enter your support message below</IonLabel>
              <IonTextarea name="message" value={message} spellCheck={false} autocapitalize="off" rows={6} onIonChange={e => setMessage(e.detail.value!)}
                required>
              </IonTextarea>
            </IonItem>

            {formSubmitted && messageError && <IonText color="danger">
              <p className="ion-padding-start">
                Support message is required
              </p>
            </IonText>}
          </IonList>

          <IonRow>
            <IonCol>
              <IonButton type="submit" expand="block">Submit</IonButton>
            </IonCol>
          </IonRow>
        </form> */}
        <div>
          <h1 className="ion-text-center">ASSA ABLOY</h1>
        </div>
        <IonList>
          <IonItem href="mailto:americas.servicedesk@assaabloy.com">
            <IonLabel className="ion-text-wrap">
              For support, please email{" "}
              <a href="mailto:americas.servicedesk@assaabloy.com">
                americas.servicedesk@assaabloy.com
              </a>
              .
            </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>

      <IonToast
        isOpen={showToast}
        duration={3000}
        message="Your support request has been sent"
        onDidDismiss={() => setShowToast(false)}
      />
      <Footer />
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  component: SupportPage,
});
