import React, { useEffect, useState } from "react";
import {
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonList,
  IonPage,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonToolbar,
} from "@ionic/react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LabelPrinterItem from "../components/LabelPrinterItem";
import LabelPrinterItemSkeleton from "../components/LabelPrinterItemSkeleton";
import "./LabelPrinters.scss";
import { labelPrintersData } from "../data/LabelPrintersData";

const LabelPrintersPage: React.FC = () => {
  const [groupSelected, setGroupSelected] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [serverSelected, setServerSelected] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const searchOnChangeHandler = (searchInputValue: string) => {
    setSearchValue(searchInputValue);
    console.log(searchInputValue);
  };

  const selectGroupOnChangeHandler = (groupSelectedValue: string) => {
    setGroupSelected(groupSelectedValue);
    console.log(groupSelectedValue);
  };

  const selectServerOnChangeHandler = (serverSelectedValue: string) => {
    setServerSelected(serverSelectedValue);
    console.log(serverSelectedValue);
  };

  function getContent() {
    return document.querySelector("ion-content");
  }

  function scrollToTop() {
    getContent()!.scrollToTop(1);
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  }, []);

  return (
    <IonPage>
      <Header appTitle="Label Printers" backButtonHref="/" backIsShown={true} />
      <IonHeader>
        <IonToolbar>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonSelect
                  placeholder="All"
                  onIonChange={(e) =>
                    selectGroupOnChangeHandler(e.detail.value)
                  }
                >
                  <IonSelectOption value={"All"}>All</IonSelectOption>
                  <IonSelectOption value={"Ameristar"}>
                    Ameristar
                  </IonSelectOption>
                  <IonSelectOption value={"Berlin"}>Berlin</IonSelectOption>
                  <IonSelectOption value={"Canada"}>Canada</IonSelectOption>
                  <IonSelectOption value={"DCG"}>DCG</IonSelectOption>
                  <IonSelectOption value={"Default"}>Default</IonSelectOption>
                  <IonSelectOption value={"DoorAndFlash"}>
                    DoorAndFlash
                  </IonSelectOption>
                  <IonSelectOption value={"EMSO"}>EMSO</IonSelectOption>
                  <IonSelectOption value={"Medeco"}>Medeco</IonSelectOption>
                  <IonSelectOption value={"Mexico"}>Mexico</IonSelectOption>
                  <IonSelectOption value={"Pemko"}>Pemko</IonSelectOption>
                  <IonSelectOption value={"Rockwood"}>Rockwood</IonSelectOption>
                  <IonSelectOption value={"Sargent"}>Sargent</IonSelectOption>
                </IonSelect>
              </IonCol>
              <IonCol>
                <IonSelect
                  placeholder="All"
                  onIonChange={(e) =>
                    selectServerOnChangeHandler(e.detail.value)
                  }
                >
                  <IonSelectOption value={"All"}>All</IonSelectOption>
                  <IonSelectOption value={"PD 2197"}>PD 2197</IonSelectOption>
                  <IonSelectOption value={"PD 2198"}>PD 2198</IonSelectOption>
                  <IonSelectOption value={"PD 2200"}>PD 2200</IonSelectOption>
                  <IonSelectOption value={"PD BLN7"}>PD BLN7</IonSelectOption>
                  <IonSelectOption value={"NP 2185"}>NP 2185</IonSelectOption>
                </IonSelect>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonSearchbar
            onIonChange={(e) => searchOnChangeHandler(e.detail.value!)}
          ></IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {!isLoading && labelPrintersData.length > 0 ? (
            labelPrintersData.map((prn) => (
              <LabelPrinterItem
                labelPrinter={prn}
                groupSelected={groupSelected}
                searchValue={searchValue}
                serverSelected={serverSelected}
              />
            ))
          ) : (
            <LabelPrinterItemSkeleton />
          )}
        </IonList>
      </IonContent>
      <IonFab vertical="bottom" horizontal="end" edge slot="fixed">
        <IonFabButton>{/* <IonIcon icon={settings} /> */}</IonFabButton>
      </IonFab>
      <Footer />
    </IonPage>
  );
};

export default LabelPrintersPage;
