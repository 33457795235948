import { UserActions } from "./user.actions";
import { UserState } from "./user.state";

export function userReducer(state: UserState, action: UserActions): UserState {
  switch (action.type) {
    case "set-user-loading":
      return { ...state, loading: action.isLoading };
    case "set-user-data":
      return { ...state, ...action.data };
    case "set-username":
      return { ...state, username: action.username };
    case "set-has-seen-tutorial":
      return { ...state, hasSeenTutorial: action.hasSeenTutorial };
    case "set-dark-mode":
      return { ...state, darkMode: action.darkMode };
    case "set-is-loggedin":
      return { ...state, isLoggedin: action.isLoggedIn };
    case "set-encoded-creds":
      return { ...state, encodedCreds: action.encodedCreds };
    case "set-responsible":
      return { ...state, responsible: action.responsible };
    case "set-user-address":
      return { ...state, userAddress: action.userAddress };
    case "set-is-authenticated-azure":
      return { ...state, isAuthenticatedAzure: action.isAuthenticatedAzure }; //Azure AD
    case "set-is-authenticated-orch":
      return { ...state, isAuthenticatedOrch: action.isAuthenticatedOrch }; //JDE Orch
  }
}
