import React from "react";
import {
  IonCol,
  IonGrid,
  IonItem,
  IonRow,
  IonSkeletonText,
} from "@ionic/react";

const LabelPrinterItemSkeleton: React.FC = (): JSX.Element => {
  return (
    <IonItem detail={false}>
      <IonGrid>
        <IonRow>
          <strong>
            <IonSkeletonText
              animated
              style={{ width: "240px", height: "1.5em" }}
            />
          </strong>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonSkeletonText
              animated
              style={{ width: "80px", height: "1.5em" }}
            />
          </IonCol>
          <IonCol>
            <IonSkeletonText
              animated
              style={{ width: "80px", height: "1.5em" }}
            />
          </IonCol>
          <IonCol>
            <IonSkeletonText
              animated
              style={{ width: "80px", height: "1.5em" }}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonSkeletonText
            animated
            style={{ width: "240px", height: "1.5em" }}
          />
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default LabelPrinterItemSkeleton;
