import React from "react";
import {
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonFooter,
} from "@ionic/react";
import {
  help,
  homeOutline,
  informationCircleOutline,
  person,
} from "ionicons/icons";

const Footer: React.FC = (): JSX.Element => {
  return (
    <IonTabBar slot="bottom" color="primary">
      <IonTabButton tab="schedule" href="/home">
        <IonIcon icon={homeOutline} />
        <IonLabel>Home</IonLabel>
      </IonTabButton>
      {/* <IonTabButton tab="account" href="/account">
        <IonIcon icon={person} />
        <IonLabel>Account</IonLabel>
      </IonTabButton> */}
      <IonTabButton tab="map" href="/support">
        <IonIcon icon={help} />
        <IonLabel>Support</IonLabel>
      </IonTabButton>
      <IonTabButton tab="about" href="/about">
        <IonIcon icon={informationCircleOutline} />
        <IonLabel>About</IonLabel>
      </IonTabButton>
    </IonTabBar>
  );
};

export default Footer;
