import React from "react";
import {
  IonButton,
  IonCol,
  IonFooter,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import {
  checkmarkCircleOutline,
  closeCircleOutline,
  saveOutline,
} from "ionicons/icons";

const SaveButtonFooter: React.FC = (): JSX.Element => {
  return (
    <IonFooter collapse="fade">
      <IonToolbar>
        <IonItem className="ion-text-center">
          <IonGrid>
            <IonRow>
              <IonCol></IonCol>
              <IonCol className="ion-text-center">
                <IonButton size="large" color="light">
                  <IonIcon slot="start" icon={saveOutline}></IonIcon>
                  Save
                </IonButton>
              </IonCol>
              <IonCol></IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonToolbar>
    </IonFooter>
  );
};

export default SaveButtonFooter;
