import React from "react";
import { IonContent, IonPage } from "@ionic/react";
//import "./About.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";

interface AboutProps {}

const AboutPage: React.FC<AboutProps> = () => {
  // const [showPopover, setShowPopover] = useState(false);
  // const [popoverEvent, setPopoverEvent] = useState<MouseEvent>();
  // const [location, setLocation] = useState<
  //   "madison" | "austin" | "chicago" | "seattle"
  // >("madison");
  // const [conferenceDate, setConferenceDate] = useState(
  //   "2047-05-17T00:00:00-05:00"
  // );

  // const selectOptions = {
  //   header: "Select a Location",
  // };

  // const presentPopover = (e: React.MouseEvent) => {
  //   setPopoverEvent(e.nativeEvent);
  //   setShowPopover(true);
  // };

  // function displayDate(date: string, dateFormat: string) {
  //   return format(parseISO(date), dateFormat);
  // }

  return (
    <IonPage id="about-page">
      <Header
        appTitle="JDE Mobile Apps"
        backButtonHref="/"
        backIsShown={true}
      />
      <IonContent>
        {/* <IonHeader className="ion-padding">
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton onClick={presentPopover}>
                <IonIcon slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader> */}

        {/* <div className="about-header"> */}
        {/* Instead of loading an image each time the select changes, use opacity to transition them */}
        {/* <div
            className="about-image madison"
            style={{ opacity: location === "madison" ? "1" : undefined }}
          ></div>
          <div
            className="about-image austin"
            style={{ opacity: location === "austin" ? "1" : undefined }}
          ></div>
          <div
            className="about-image chicago"
            style={{ opacity: location === "chicago" ? "1" : undefined }}
          ></div>
          <div
            className="about-image seattle"
            style={{ opacity: location === "seattle" ? "1" : undefined }}
          ></div>
        </div> */}
        <div>
          <h3 className="ion-text-center">About</h3>
        </div>
        <div className="about-info">
          <p className="ion-padding-start ion-padding-end">
            The Assa Abloy JDE Mobile Apps provide a mobile interface to our JD
            Edwards ERP system. This system was created and is maintained by the
            ERP Technology Team.
          </p>
          <p className="ion-padding-start ion-padding-end">Version 1.2.6</p>
          {/* 1.2.6 20221010B */}
        </div>

        {/* <h3 className="ion-padding-top ion-padding-start">Details</h3>

          <IonList lines="none">
            <IonItem>
              <IonLabel>Location</IonLabel>
              <IonSelect
                value={location}
                interfaceOptions={selectOptions}
                onIonChange={(e) => setLocation(e.detail.value as any)}
              >
                <IonSelectOption value="madison">Madison, WI</IonSelectOption>
                <IonSelectOption value="austin">Austin, TX</IonSelectOption>
                <IonSelectOption value="chicago">Chicago, IL</IonSelectOption>
                <IonSelectOption value="seattle">Seattle, WA</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem button={true} id="open-date-input">
              <IonLabel>Date</IonLabel>
              <IonText slot="end">
                {displayDate(conferenceDate, "MMM dd, yyyy")}
              </IonText>
              <IonPopover
                id="date-input-popover"
                trigger="open-date-input"
                showBackdrop={false}
                side="top"
                alignment="end"
              >
                <IonDatetime
                  max="2056"
                  value={conferenceDate}
                  onIonChange={(e) => setConferenceDate(e.detail.value!)}
                  presentation="date"
                ></IonDatetime>
              </IonPopover>
            </IonItem>
          </IonList>

          <h3 className="ion-padding-top ion-padding-start">Internet</h3>

          <IonList lines="none">
            <IonItem>
              <IonLabel>Wifi network</IonLabel>
              <IonLabel className="ion-text-end">
                ica{displayDate(conferenceDate, "y")}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Password</IonLabel>
              <IonLabel className="ion-text-end">makegoodthings</IonLabel>
            </IonItem>
          </IonList>
        </div> */}
      </IonContent>

      {/* <IonPopover */}
      {/* isOpen={showPopover} */}
      {/* event={popoverEvent} */}
      {/* onDidDismiss={() => setShowPopover(false)} */}
      {/* > */}
      {/* <AboutPopover dismiss={() => setShowPopover(false)} /> */}
      {/* </IonPopover> */}
      <Footer />
    </IonPage>
  );
};

export default React.memo(AboutPage);
