import React from "react";
import {
  IonItem,
  IonGrid,
  IonCol,
  IonRow,
  IonLabel,
  IonSkeletonText,
} from "@ionic/react";

const PoaDetailsPoLineSkeleton: React.FC = (): JSX.Element => {
  return (
    <IonItem>
      <IonLabel>
        <IonGrid>
          <IonRow>
            <IonCol>
              <strong>
                <IonSkeletonText
                  animated
                  style={{ width: "100px", height: "1.5em" }}
                />
              </strong>
              <br />
              Total
              <br />
              Unit Price
              <br />
              Order Quantity
            </IonCol>
            <IonCol className="ion-text-right">
              <br />
              <IonSkeletonText
                animated
                style={{ width: "30px", height: "1em" }}
              />
              <br />
              <IonSkeletonText
                animated
                style={{ width: "30px", height: "1em" }}
              />
              <br />
              <IonSkeletonText
                animated
                style={{ width: "30px", height: "1em" }}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonLabel>
    </IonItem>
  );
};

export default PoaDetailsPoLineSkeleton;
