import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import Menu from "./components/Menu";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import MainTabs from "./pages/MainTabs";
import { connect } from "./data/connect";
import { AppContextProvider } from "./data/AppContext";
import { loadConfData } from "./data/sessions/sessions.actions";
import {
  setIsLoggedIn,
  setUsername,
  loadUserData,
  setIsAuthenticatedAzure,
  setIsAuthenticatedOrch,
} from "./data/user/user.actions";
import AccountPage from "./pages/Account";
import LabelPrintersPage from "./pages/LabelPrinters";
import LoginPage from "./pages/Login";
// import Signup from "./pages/Signup";
import SupportPage from "./pages/Support";
// import Tutorial from "./pages/Tutorial";
// import HomeOrTutorial from "./components/Ho+meOrTutorial";
import HomeOrLogin from "./components/HomeOrLogin";
import Logout from "./components/Logout";
import { Schedule } from "./models/Schedule";
// import RedirectToLogin from "./components/RedirectToLogin";
import AboutPage from "./pages/About";
import HomePage from "./pages/Home";
import InventoryInquiryPage from "./pages/InventoryInquiry";
import PoaCountsPage from "./pages/PoaCounts";
import PoaDetailsPage from "./pages/PoaDetails";
import PoaListPage from "./pages/PoaList";
import PriceInquiryPage from "./pages/PriceInquiry";

const App: React.FC = () => {
  return (
    <AppContextProvider>
      <IonicAppConnected />
    </AppContextProvider>
  );
};

interface StateProps {
  darkMode: boolean;
  schedule: Schedule;
}

interface DispatchProps {
  loadConfData: typeof loadConfData;
  loadUserData: typeof loadUserData;
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
  setIsAuthenticatedOrch: typeof setIsAuthenticatedOrch;
  setIsAuthenticatedAzure: typeof setIsAuthenticatedAzure;
}

interface IonicAppProps extends StateProps, DispatchProps {}

const IonicApp: React.FC<IonicAppProps> = ({
  darkMode,
  schedule,
  setIsLoggedIn,
  setUsername,
  loadConfData,
  loadUserData,
}) => {
  useEffect(() => {
    loadUserData();
    loadConfData();
    // eslint-disable-next-line
  }, []);

  return schedule.groups.length === 0 ? (
    <div></div>
  ) : (
    <IonApp className={`${darkMode ? "dark-theme" : ""}`}>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            {/*
                We use IonRoute here to keep the tabs state intact,
                which makes transitions between tabs and non tab pages smooth
                */}
            <Route path="/tabs" render={() => <MainTabs />} />
            <Route path="/about" component={AboutPage} />
            <Route path="/account" component={AccountPage} />
            <Route path="/home" component={HomePage} />
            <Route path="/inventory-inquiry" component={InventoryInquiryPage} />
            <Route path="/label-printers" component={LabelPrintersPage} />
            <Route path="/login" component={LoginPage} />
            <Route path="/logout" component={Logout} />
            <Route path="/price-inquiry" component={PriceInquiryPage} />
            <Route path="/po-approval" exact={true} component={PoaCountsPage} />
            <Route
              path="/po-approval/details/:docType/:poNumber/:company"
              component={PoaDetailsPage}
            />
            <Route
              path="/po-approval/line-detail"
              exact={true}
              component={PoaCountsPage}
            />
            <Route path="/po-approval/list/:docType" component={PoaListPage} />

            {/* <Route path="/signup" component={Signup} /> */}
            <Route path="/support" component={SupportPage} />
            {/* <Route path="/tutorial" component={Tutorial} /> */}
            {/* <Route
              path="/logout"
              render={() => {
                return (
                  <RedirectToLogin
                    setIsLoggedIn={setIsLoggedIn}
                    setUsername={setUsername}
                    setIsAuthenticatedAzure={setIsAuthenticatedAzure}
                    setIsAuthenticatedOrch={setIsAuthenticatedOrch}
                  />
                );
              }}
            /> */}
            <Route path="/" component={HomeOrLogin} exact={true} />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    schedule: state.data.schedule,
  }),
  mapDispatchToProps: {
    loadConfData,
    loadUserData,
    setIsLoggedIn,
    setUsername,
    setIsAuthenticatedAzure,
    setIsAuthenticatedOrch,
  },
  component: IonicApp,
});
