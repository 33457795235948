import React from "react";
import { connect } from "../data/connect";
import { Redirect } from "react-router";
import { RouteComponentProps } from "react-router";
import {
  setIsAuthenticatedAzure,
  setIsAuthenticatedOrch,
  setIsLoggedIn,
  setUsername,
  setEncodedCreds,
  setUserAddress,
} from "../data/user/user.actions";

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  isAuthenticatedAzure: string;
  isAuthenticatedOrch: string;
}

interface DispatchProps {
  setIsAuthenticatedAzure: typeof setIsAuthenticatedAzure;
  setIsAuthenticatedOrch: typeof setIsAuthenticatedOrch;
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
  setEncodedCreds: typeof setEncodedCreds;
  setUserAddress: typeof setUserAddress;
}

interface LogoutProps extends OwnProps, StateProps, DispatchProps {}

const Logout: React.FC<LogoutProps> = ({
  isAuthenticatedAzure,
  isAuthenticatedOrch,
  setIsLoggedIn: setIsLoggedInAction,
  history,
  setUsername: setUsernameAction,
  setEncodedCreds: setEncodedCredsAction,
  setUserAddress: setUserAddressAction,
  setIsAuthenticatedAzure: setIsAuthenticatedAzureAction,
  setIsAuthenticatedOrch: setIsAuthenticatedOrchAction,
}) => {
  console.log("start Logout component");
  console.log("isAuthenticatedAzure: " + isAuthenticatedAzure);
  console.log("isAuthenticatedOrch: " + isAuthenticatedOrch);
  // setIsAuthenticatedAzureAction("0");
  setIsAuthenticatedOrchAction("0");
  setIsLoggedInAction(false);
  setUsernameAction(undefined);
  setEncodedCredsAction(undefined);
  setUserAddressAction(undefined);
  console.log("Logout component after reset user values");
  console.log("isAuthenticatedAzure: " + isAuthenticatedAzure);
  console.log("isAuthenticatedOrch: " + isAuthenticatedOrch);
  console.log("Logout component before redirect to Login");
  // sessionStorage.clear();
  // localStorage.clear();
  return <Redirect to="/" />;
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    isAuthenticatedAzure: state.user.isAuthenticatedAzure, //Azure AD
    isAuthenticatedOrch: state.user.isAuthenticatedOrch, //JDE Orch
  }),
  mapDispatchToProps: {
    setIsAuthenticatedAzure,
    setIsAuthenticatedOrch,
    setIsLoggedIn,
    setUsername,
    setEncodedCreds,
    setUserAddress,
  },
  component: Logout,
});
