import React from "react";
import {
  IonItem,
  IonList,
  IonLabel,
  IonIcon,
  IonSkeletonText,
} from "@ionic/react";
import {
  callOutline,
  chatbubbleEllipsesOutline,
  mailOutline,
} from "ionicons/icons";

const PoaDetailsPoHeaderSkeleton: React.FC = (): JSX.Element => {
  return (
    <IonList>
      <IonItem>
        <IonLabel>
          <strong>Total Amount</strong>
          <br />
          <IonSkeletonText animated style={{ width: "50px", height: "1em" }} />
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>
          <strong>Branch/Plant</strong>
          <br />
          <IonSkeletonText animated style={{ width: "50px", height: "1em" }} />
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>
          <strong>Requested Date</strong>
          <br />
          <IonSkeletonText animated style={{ width: "60px", height: "1em" }} />
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>
          <strong>Transaction Date</strong>
          <br />
          <IonSkeletonText animated style={{ width: "60px", height: "1em" }} />
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>
          <strong>Buyer Address</strong>
          <br />
          <IonSkeletonText animated style={{ width: "20px", height: "1em" }} />
          <br />
          <IonSkeletonText animated style={{ width: "120px", height: "1em" }} />
          <br />
          <IonSkeletonText animated style={{ width: "100px", height: "1em" }} />
          <br />
          <IonSkeletonText
            animated
            style={{ width: "40px", height: "1em" }}
          />,{" "}
          <IonSkeletonText animated style={{ width: "10px", height: "1em" }} />{" "}
          <IonSkeletonText animated style={{ width: "40px", height: "1em" }} />
          <br />
          <IonIcon icon={callOutline}></IonIcon>
          &nbsp;&nbsp;
          <IonIcon icon={chatbubbleEllipsesOutline}></IonIcon>
          &nbsp;&nbsp;
          <IonIcon icon={mailOutline}></IonIcon>
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>
          <strong>Supplier Address</strong>
          <br />
          <IonSkeletonText animated style={{ width: "20px", height: "1em" }} />
          <br />
          <IonSkeletonText animated style={{ width: "120px", height: "1em" }} />
          <br />
          <IonSkeletonText animated style={{ width: "100px", height: "1em" }} />
          <br />
          <IonSkeletonText
            animated
            style={{ width: "40px", height: "1em" }}
          />,{" "}
          <IonSkeletonText animated style={{ width: "10px", height: "1em" }} />{" "}
          <IonSkeletonText animated style={{ width: "40px", height: "1em" }} />
          <br />
          <IonIcon icon={callOutline}></IonIcon>
          &nbsp;&nbsp;
          <IonIcon icon={chatbubbleEllipsesOutline}></IonIcon>
          &nbsp;&nbsp;
          <IonIcon icon={mailOutline}></IonIcon>
        </IonLabel>
      </IonItem>
    </IonList>
  );
};

//TODO: get phones and emails

export default PoaDetailsPoHeaderSkeleton;
