import React from "react";
import { IonItem, IonGrid, IonCol, IonRow } from "@ionic/react";

interface PoaListItemProps {
  id: number;
  age: string;
  amount: string;
  company: string;
  currency: string;
  docType: string;
  orderDate: string;
  poNumber: string;
  responsible: string;
  supplierName: string;
}

const PoaListItem: React.FC<PoaListItemProps> = (props): JSX.Element => {
  const href: string =
    "/po-approval/details/" +
    props.docType +
    "/" +
    props.poNumber +
    "/" +
    props.company;
  console.log(props.id);
  const index: number = props.id + 1;
  return (
    <IonItem key={index} href={href}>
      <IonGrid key={"grid-" + index}>
        <IonRow key={"row-" + index}>
          <IonCol key={"col-" + index}>
            <strong>{props.responsible}</strong>
            <br />
            <span style={{ color: "lightblue" }}>{props.supplierName}</span>
            <br />
            {props.poNumber} - {props.docType} - {props.company}
          </IonCol>
          <IonCol key={"col-9999" + index} className="ion-text-right">
            <span style={{ color: "red" }}>{props.age} Days Old</span>
            <br />
            {props.amount} {props.currency}
            <br />
            {props.orderDate}
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default PoaListItem;
