import React, { useRef, useState } from "react";
import {
  IonButtons,
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonItem,
  IonLabel,
  IonInput,
  IonAlert,
} from "@ionic/react";
// import { OverlayEventDetail } from "@ionic/core/components";
// import { RouteComponentProps } from "react-router";

// interface PoaApproveOrderProps extends RouteComponentProps {
interface PoaApproveOrderProps {
  company: string;
  docType: string;
  encodedCreds: string;
  history: any;
  poNumber: string;
  responsible: string;
  supplierName: string;
}

const PoaApproveOrder: React.FC<PoaApproveOrderProps> = ({
  company,
  docType,
  encodedCreds,
  poNumber,
  responsible,
  supplierName,
  history, //note: important to put history below props
}): JSX.Element => {
  const approveModalRef = useRef<HTMLIonModalElement>(null);
  const remarksInputRef = useRef<HTMLIonInputElement>(null);
  // const postApproveUrl: string = "/po-approval/list/" + docType; // 20220927 - go back to poa count page
  const postApproveUrl: string = "/po-approval";
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const url =
    "https://jdedvorch.assaabloy.net/jderest/orchestrator/JDE_ORCH_PO_Approval_Approve";

  async function approveApiCall(
    url: string = "",
    bodyData: object = {},
    encodedCreds: string = ""
  ) {
    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + encodedCreds,
        },
        body: JSON.stringify(bodyData),
      });
      console.log("bodyData: " + JSON.stringify(bodyData));
      console.log("response: " + JSON.stringify(response));
      if (response.ok) {
        console.log("response.ok is truthy");
        return response.json();
      } else {
        console.log("response.ok is falsey");
        console.log(response.status);
        console.log(response.statusText);
        //403 Forbidden if creds wrong
      }
    } catch (error) {
      //console.log('ERROR:', err.message);
      //Failed to fetch //wrong url
      //wrong url also returns net::ERR_NAME_NOT_RESOLVED (but I have not figured out how to catch that)
      console.error("error calling approval orch" + error);
      //wrong url returns net::ERR_NAME_NOT_RESOLVED and this error is undefined
    }
  }

  const approve = async () => {
    // e.preventDefault();
    const remarks = remarksInputRef.current?.value; //TODO figure out how to add remarks to the body for the jde orch (hitesh)
    approveModalRef.current?.dismiss();
    // setFormSubmitted(true);
    const bodyData = {
      OrTy: docType,
      OrCo: company,
      OrNum: poNumber,
      P43081_Version: "EMG0001", //TODO soft-code version
    };
    approveApiCall(url, bodyData, encodedCreds)
      .then((loadedData) => {
        console.log("login loadedData: " + JSON.stringify(loadedData));
        if (loadedData) {
          if (loadedData.ServiceRequest1) {
            //success
            console.log("approveApiCall succeeded");
            setAlertMessage("Order Approved!");
            setShowAlert(true);
            //note: determined alert will stay on screen even when the "background" screen is updating to the new page that history.push is going to.
            // history.push(postApproveUrl, { direction: "none" });
          } else {
            //fail
            console.log(
              "approveApiCall failed (loadedData has no ServiceRequest1)"
            );
            setAlertMessage("Error: Order Approval Failed!");
            setShowAlert(true);
            // history.push(postApproveUrl, { direction: "none" });
          }
        } else {
          //fail
          console.log("approveApiCall failed (loadedData is falsey)");
          setAlertMessage("Error: Order Approval Failed!");
          setShowAlert(true);
          // history.push(postApproveUrl, { direction: "none" });
        }
      })
      .catch((error) => {
        console.log("approveApiCall.then.catch");
      });
  };

  // function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
  //   if (ev.detail.role === "confirm") {
  //     setMessage(`Hello, ${ev.detail.data}!`);
  //   }
  // }

  function postApiCall() {
    history.push(postApproveUrl, { direction: "none" });
  }

  return (
    <>
      <IonModal
        ref={approveModalRef}
        trigger="open-approve-modal"
        // onWillDismiss={(ev) => onWillDismiss(ev)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => approveModalRef.current?.dismiss()}>
                Cancel
              </IonButton>
            </IonButtons>
            <IonTitle className="ion-text-center">Approve Order</IonTitle>
            <IonButtons slot="end">
              <IonButton strong={true} onClick={() => approve()}>
                Approve
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonLabel>
            <h1>{responsible}</h1>
            {supplierName}
            <br />
            {poNumber} - {docType} - {company}
            <br />
            <br />
          </IonLabel>
          <IonItem>
            <IonLabel position="stacked">Remarks</IonLabel>
            <IonInput ref={remarksInputRef} type="text" placeholder="Remarks" />
          </IonItem>
        </IonContent>
      </IonModal>
      <IonAlert
        isOpen={showAlert}
        header={"Confirmation"}
        message={alertMessage}
        buttons={["OK"]}
        //onDidDismiss={() => postApiCall()}
      ></IonAlert>
    </>
  );
};

export default PoaApproveOrder;
