import React from "react";
import { IonItem, IonList, IonLabel, IonIcon } from "@ionic/react";
import {
  PoaDetailsAddressModel,
  PoaDetailsPoHeaderModel,
} from "../models/PoaDetails";
import {
  callOutline,
  chatbubbleEllipsesOutline,
  mailOutline,
} from "ionicons/icons";

interface PoaDetailsPoHeaderProps {
  poHeader: PoaDetailsPoHeaderModel;
  buyerAddress: PoaDetailsAddressModel;
  supplierAddress: PoaDetailsAddressModel;
}

const PoaDetailsPoHeader: React.FC<PoaDetailsPoHeaderProps> = (
  props
): JSX.Element => {
  return (
    <>
      <IonList>
        <IonItem>
          <IonLabel>
            <strong>Total Amount</strong>
            <br />
            {props.poHeader.amount} {props.poHeader.currency}
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <strong>Branch/Plant</strong>
            <br />
            {props.poHeader.branchPlant}
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <strong>Requested Date</strong>
            <br />
            {props.poHeader.requestDate}
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <strong>Transaction Date</strong>
            <br />
            {props.poHeader.orderDate}
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <strong>Buyer Address</strong>
            <br />
            {props.buyerAddress.companyName}
            <br />
            {props.buyerAddress.companyName}
            <br />
            {props.buyerAddress.address1}
            <br />
            {props.buyerAddress.city}, {props.buyerAddress.country}{" "}
            {props.buyerAddress.postalCode}
            <br />
            <a href="tel:828-446-3858">
              <IonIcon icon={callOutline}></IonIcon>
            </a>
            &nbsp;&nbsp;
            <a href="sms:828-446-3858">
              <IonIcon icon={chatbubbleEllipsesOutline}></IonIcon>
            </a>
            &nbsp;&nbsp;
            <a href="mailto:ron.adams@assaabloy.com">
              <IonIcon icon={mailOutline}></IonIcon>
            </a>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <strong>Supplier Address</strong>
            <br />
            {props.supplierAddress.companyName}
            <br />
            {props.supplierAddress.address1}
            <br />
            {props.supplierAddress.address2}
            <br />
            {props.supplierAddress.city}, {props.supplierAddress.country}{" "}
            {props.supplierAddress.postalCode}
            <br />
            <a href="tel:828-446-3858">
              <IonIcon icon={callOutline}></IonIcon>
            </a>
            &nbsp;&nbsp;
            <a href="sms:828-446-3858">
              <IonIcon icon={chatbubbleEllipsesOutline}></IonIcon>
            </a>
            &nbsp;&nbsp;
            <a href="mailto:ron.adams@assaabloy.com">
              <IonIcon icon={mailOutline}></IonIcon>
            </a>
          </IonLabel>
        </IonItem>
      </IonList>
    </>
  );
};

//TODO: get phones and emails

export default PoaDetailsPoHeader;
