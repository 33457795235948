import React from "react";
import { IonCol, IonGrid, IonItem, IonRow } from "@ionic/react";
import { LabelPrinterModel } from "../models/LabelPrinter";

interface LabelPrinterItemProps {
  labelPrinter: LabelPrinterModel;
  groupSelected: string;
  searchValue: string;
  serverSelected: string;
}

const LabelPrinterItem: React.FC<LabelPrinterItemProps> = (
  props
): JSX.Element => {
  // const url =
  //   '<a href="http://' +
  //   props.labelPrinter.address +
  //   '" target="_blank">' +
  //   props.labelPrinter.address +
  //   "</a>";
  if (
    (props.groupSelected === "" ||
      props.groupSelected === "All" ||
      props.groupSelected === props.labelPrinter.group) &&
    (props.labelPrinter.address
      .toUpperCase()
      .indexOf(props.searchValue.toUpperCase()) !== -1 ||
      props.labelPrinter.description
        .toUpperCase()
        .indexOf(props.searchValue.toUpperCase()) !== -1 ||
      props.labelPrinter.driver
        .toUpperCase()
        .indexOf(props.searchValue.toUpperCase()) !== -1 ||
      props.labelPrinter.server
        .toUpperCase()
        .indexOf(props.searchValue.toUpperCase()) !== -1) &&
    (props.serverSelected === "" ||
      props.serverSelected === "All" ||
      props.serverSelected === props.labelPrinter.server)
  ) {
    if (props.labelPrinter.address == "NUL") {
      return (
        <IonItem detail={false}>
          <IonGrid>
            <IonRow>
              <strong>{props.labelPrinter.description}</strong>
            </IonRow>
            <IonRow>
              <IonCol>{props.labelPrinter.group}</IonCol>
              <IonCol>{props.labelPrinter.server}</IonCol>
              <IonCol>{props.labelPrinter.address}</IonCol>
            </IonRow>
            <IonRow>{props.labelPrinter.driver}</IonRow>
          </IonGrid>
        </IonItem>
      );
    } else {
      const url = "http://" + props.labelPrinter.address;
      return (
        <IonItem href={url} detail={true}>
          <IonGrid>
            <IonRow>
              <strong>{props.labelPrinter.description}</strong>
            </IonRow>
            <IonRow>
              <IonCol>{props.labelPrinter.group}</IonCol>
              <IonCol>{props.labelPrinter.server}</IonCol>
              <IonCol className="faux-link">
                {props.labelPrinter.address}
              </IonCol>
            </IonRow>
            <IonRow>{props.labelPrinter.driver}</IonRow>
          </IonGrid>
        </IonItem>
      );
    }
  } else {
    return <></>;
  }
};
// <IonItem>
//   <IonGrid>
//     <IonRow>001 - PMI - Corbin Berlin (p001)</IonRow>
//     <IonRow>
//       <IonCol>Berlin</IonCol>
//       <IonCol>
//         <a href="http://10.203.0.75" target="_blank">
//           10.203.0.75
//         </a>
//       </IonCol>
//     </IonRow>
//     <IonRow>Intermec EasyCoder PM4i(IPL version)</IonRow>
//   </IonGrid>
// </IonItem>

export default LabelPrinterItem;
