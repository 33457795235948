import React from "react";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";

const PriceInquiryType: React.FC<{
  selectedValue: "simple" | "advanced";
  onSelectValue: (value: "simple" | "advanced") => void;
}> = (props) => {
  const priceInquiryTypeHandler = (event: CustomEvent) => {
    props.onSelectValue(event.detail.value);
  };
  return (
    <IonSegment
      value={props.selectedValue}
      onIonChange={priceInquiryTypeHandler}
    >
      <IonSegmentButton value="simple">
        <IonLabel>Pricing</IonLabel>
      </IonSegmentButton>
      <IonSegmentButton value="advanced">
        <IonLabel>Advanced Pricing</IonLabel>
      </IonSegmentButton>
    </IonSegment>
  );
};

export default PriceInquiryType;
