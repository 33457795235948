import {
  getUserData,
  setIsLoggedInData,
  setUsernameData,
  setHasSeenTutorialData,
  setEncodedCredsData,
  setUserAddressData,
  setResponsibleData,
  setIsAuthenticatedAzureData,
  setIsAuthenticatedOrchData,
} from "../dataApi";
import { ActionType } from "../../util/types";
import { UserState } from "./user.state";

export const loadUserData = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  const data = await getUserData();
  dispatch(setData(data));
  dispatch(setLoading(false));
};

export const setLoading = (isLoading: boolean) =>
  ({
    type: "set-user-loading",
    isLoading,
  } as const);

export const setData = (data: Partial<UserState>) =>
  ({
    type: "set-user-data",
    data,
  } as const);

export const logoutUser = () => async (dispatch: React.Dispatch<any>) => {
  await setIsLoggedInData(false);
  dispatch(setUsername());
};

export const setIsLoggedIn =
  (isLoggedIn: boolean) => async (dispatch: React.Dispatch<any>) => {
    await setIsLoggedInData(isLoggedIn);
    return {
      type: "set-is-loggedin",
      isLoggedIn,
    } as const;
  };

export const setIsAuthenticatedAzure =
  (isAuthenticatedAzure?: string) => async (dispatch: React.Dispatch<any>) => {
    await setIsAuthenticatedAzureData(isAuthenticatedAzure);
    return {
      type: "set-is-authenticated-azure",
      isAuthenticatedAzure,
    } as const;
  };

export const setIsAuthenticatedOrch =
  (isAuthenticatedOrch?: string) => async (dispatch: React.Dispatch<any>) => {
    await setIsAuthenticatedOrchData(isAuthenticatedOrch);
    return {
      type: "set-is-authenticated-orch",
      isAuthenticatedOrch,
    } as const;
  };

export const setUsername =
  (username?: string) => async (dispatch: React.Dispatch<any>) => {
    await setUsernameData(username);
    return {
      type: "set-username",
      username,
    } as const;
  };

export const setEncodedCreds =
  (encodedCreds?: string) => async (dispatch: React.Dispatch<any>) => {
    await setEncodedCredsData(encodedCreds);
    return {
      type: "set-encoded-creds",
      encodedCreds,
    } as const;
  };

export const setResponsible =
  (responsible?: string) => async (dispatch: React.Dispatch<any>) => {
    await setResponsibleData(responsible);
    return {
      type: "set-responsible",
      responsible,
    } as const;
  };

export const setUserAddress =
  (userAddress?: string) => async (dispatch: React.Dispatch<any>) => {
    await setUserAddressData(userAddress);
    return {
      type: "set-user-address",
      userAddress,
    } as const;
  };

export const setHasSeenTutorial =
  (hasSeenTutorial: boolean) => async (dispatch: React.Dispatch<any>) => {
    await setHasSeenTutorialData(hasSeenTutorial);
    return {
      type: "set-has-seen-tutorial",
      hasSeenTutorial,
    } as const;
  };

export const setDarkMode = (darkMode: boolean) =>
  ({
    type: "set-dark-mode",
    darkMode,
  } as const);

export type UserActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof setIsLoggedIn>
  | ActionType<typeof setUsername>
  | ActionType<typeof setHasSeenTutorial>
  | ActionType<typeof setDarkMode>
  | ActionType<typeof setEncodedCreds>
  | ActionType<typeof setUserAddress>
  | ActionType<typeof setResponsible>
  | ActionType<typeof setIsAuthenticatedAzure>
  | ActionType<typeof setIsAuthenticatedOrch>;
