import { Plugins } from "@capacitor/core";
import { Schedule, Session } from "../models/Schedule";
import { Speaker } from "../models/Speaker";
import { Location } from "../models/Location";

const { Storage } = Plugins;

const dataUrl = "/assets/data/data.json";
const locationsUrl = "/assets/data/locations.json";

const HAS_LOGGED_IN = "hasLoggedIn";
const HAS_SEEN_TUTORIAL = "hasSeenTutorial";
const USERNAME = "username";
const ENCODED_CREDS = "encodedCreds";
const USER_ADDRESS = "userAddress";
const RESPONSIBLE = "responsible";
const IS_AUTHENTICATED_AZURE = "isAuthenticatedAzure";
const IS_AUTHENTICATED_ORCH = "isAuthenticatedOrch";

export const getConfData = async () => {
  const response = await Promise.all([fetch(dataUrl), fetch(locationsUrl)]);
  const responseData = await response[0].json();
  const schedule = responseData.schedule[0] as Schedule;
  const sessions = parseSessions(schedule);
  const speakers = responseData.speakers as Speaker[];
  const locations = (await response[1].json()) as Location[];
  const allTracks = sessions
    .reduce((all, session) => all.concat(session.tracks), [] as string[])
    .filter((trackName, index, array) => array.indexOf(trackName) === index)
    .sort();

  const data = {
    schedule,
    sessions,
    locations,
    speakers,
    allTracks,
    filteredTracks: [...allTracks],
  };
  return data;
};

export const getUserData = async () => {
  const response = await Promise.all([
    Storage.get({ key: HAS_LOGGED_IN }),
    Storage.get({ key: HAS_SEEN_TUTORIAL }),
    Storage.get({ key: USERNAME }),
    Storage.get({ key: ENCODED_CREDS }),
    Storage.get({ key: USER_ADDRESS }),
    Storage.get({ key: RESPONSIBLE }),
    Storage.get({ key: IS_AUTHENTICATED_AZURE }),
    Storage.get({ key: IS_AUTHENTICATED_ORCH }),
  ]);
  const isLoggedin = (await response[0].value) === "true";
  const hasSeenTutorial = (await response[1].value) === "true";
  const username = (await response[2].value) || undefined;
  const encodedCreds = (await response[3].value) || undefined;
  const userAddress = (await response[4].value) || undefined;
  const responsible = (await response[5].value) || undefined;
  const isAuthenticatedAzure = (await response[6].value) || undefined;
  const isAuthenticatedOrch = (await response[7].value) || undefined;
  const data = {
    isLoggedin,
    hasSeenTutorial,
    username,
    encodedCreds,
    userAddress,
    responsible,
    isAuthenticatedAzure,
    isAuthenticatedOrch,
  };
  return data;
};

export const setIsAuthenticatedAzureData = async (
  isAuthenticatedAzure?: string
) => {
  if (!isAuthenticatedAzure) {
    await Storage.remove({ key: IS_AUTHENTICATED_AZURE });
  } else {
    await Storage.set({
      key: IS_AUTHENTICATED_AZURE,
      value: isAuthenticatedAzure,
    });
  }
};

export const setIsAuthenticatedOrchData = async (
  isAuthenticatedOrch?: string
) => {
  if (!isAuthenticatedOrch) {
    await Storage.remove({ key: IS_AUTHENTICATED_ORCH });
  } else {
    await Storage.set({
      key: IS_AUTHENTICATED_ORCH,
      value: isAuthenticatedOrch,
    });
  }
};

export const setIsLoggedInData = async (isLoggedIn: boolean) => {
  await Storage.set({ key: HAS_LOGGED_IN, value: JSON.stringify(isLoggedIn) });
};

export const setHasSeenTutorialData = async (hasSeenTutorial: boolean) => {
  await Storage.set({
    key: HAS_SEEN_TUTORIAL,
    value: JSON.stringify(hasSeenTutorial),
  });
};

export const setUsernameData = async (username?: string) => {
  if (!username) {
    await Storage.remove({ key: USERNAME });
  } else {
    await Storage.set({ key: USERNAME, value: username });
  }
};

export const setEncodedCredsData = async (encodedCreds?: string) => {
  if (!encodedCreds) {
    await Storage.remove({ key: ENCODED_CREDS });
  } else {
    await Storage.set({ key: ENCODED_CREDS, value: encodedCreds });
  }
};

export const setResponsibleData = async (responsible?: string) => {
  if (!responsible) {
    await Storage.remove({ key: RESPONSIBLE });
  } else {
    await Storage.set({ key: RESPONSIBLE, value: responsible });
  }
};

export const setUserAddressData = async (userAddress?: string) => {
  if (!userAddress) {
    await Storage.remove({ key: USER_ADDRESS });
  } else {
    await Storage.set({ key: USER_ADDRESS, value: userAddress });
  }
};

function parseSessions(schedule: Schedule) {
  const sessions: Session[] = [];
  schedule.groups.forEach((g) => {
    g.sessions.forEach((s) => sessions.push(s));
  });
  return sessions;
}
