import React from "react";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";

const PoaDetailsTabs: React.FC<{
  selectedValue: "header" | "details" | "notes" | "line-detail";
  onSelectValue: (
    value: "header" | "details" | "notes" | "line-detail"
  ) => void;
}> = (props) => {
  const poApprovalDetailsTabsHandler = (event: CustomEvent) => {
    props.onSelectValue(event.detail.value);
  };
  return (
    <IonSegment
      value={props.selectedValue}
      onIonChange={poApprovalDetailsTabsHandler}
    >
      <IonSegmentButton value="header">
        <IonLabel>Header</IonLabel>
      </IonSegmentButton>
      <IonSegmentButton value="details">
        <IonLabel>Details</IonLabel>
      </IonSegmentButton>
      <IonSegmentButton value="notes">
        <IonLabel>Notes</IonLabel>
      </IonSegmentButton>
    </IonSegment>
  );
};

export default PoaDetailsTabs;
