import React, { useState } from "react";
import { IonContent, IonPage, IonList, IonItem, IonAlert } from "@ionic/react";
import "./Account.scss";
import { setUsername } from "../data/user/user.actions";
import { connect } from "../data/connect";
import { RouteComponentProps } from "react-router";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Redirect } from "react-router";

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  username?: string;
  isAuthenticatedAzure: string;
  isAuthenticatedOrch: string;
}

// interface DispatchProps {
//   setUsername: typeof setUsername;
// }

interface DispatchProps {}

interface AccountProps extends OwnProps, StateProps, DispatchProps {}

const AccountPage: React.FC<AccountProps> = ({
  isAuthenticatedAzure,
  isAuthenticatedOrch,
  username,
}) => {
  // const [showAlert, setShowAlert] = useState(false);

  // const clicked = (text: string) => {
  //   console.log(`Clicked ${text}`);
  // };
  console.log("start of Account page");
  console.log("isAuthenticatedAzure: " + isAuthenticatedAzure);
  console.log("isAuthenticatedOrch: " + isAuthenticatedOrch);
  // if (isAuthenticatedAzure != "1" || isAuthenticatedOrch != "1") {
  if (isAuthenticatedOrch != "1") {
    console.log("redirected from Account to Login page");
    return <Redirect to="/login" />;
  }

  return (
    <IonPage id="account-page">
      <Header
        appTitle="JDE Mobile Apps"
        backButtonHref="/home"
        backIsShown={true}
      />
      {/* <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Account</IonTitle>
        </IonToolbar>
      </IonHeader> */}
      <IonContent>
        {username && (
          <div className="ion-padding-top ion-text-center">
            <img
              src="https://www.gravatar.com/avatar?d=mm&s=140"
              alt="avatar"
            />
            <h2>{username}</h2>
            <IonList inset>
              {/* <IonItem onClick={() => clicked('Update Picture')}>Update Picture</IonItem> */}
              {/* <IonItem onClick={() => setShowAlert(true)}>Change Username</IonItem> */}
              {/* <IonItem onClick={() => clicked('Change Password')}>Change Password</IonItem> */}
              <IonItem routerLink="/support" routerDirection="none">
                Support
              </IonItem>
              <IonItem routerLink="/logout" routerDirection="none">
                Logout
              </IonItem>
            </IonList>
          </div>
        )}
      </IonContent>
      {/* <IonAlert
        isOpen={showAlert}
        header="Change Username"
        buttons={[
          "Cancel",
          {
            text: "Ok",
            handler: (data) => {
              setUsername(data.username);
            },
          },
        ]}
        inputs={[
          {
            type: "text",
            name: "username",
            value: username,
            placeholder: "username",
          },
        ]}
        onDidDismiss={() => setShowAlert(false)}
      /> */}
      <Footer />
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticatedAzure: state.user.isAuthenticatedAzure,
    isAuthenticatedOrch: state.user.isAuthenticatedOrch,
    username: state.user.username,
  }),
  // mapDispatchToProps: {
  //   setUsername,
  // },
  component: AccountPage,
});
