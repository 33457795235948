import React from "react";
import { connect } from "../data/connect";
import { Redirect } from "react-router";

interface StateProps {
  isAuthenticatedAzure: string;
  isAuthenticatedOrch: string;
}

const HomeOrLogin: React.FC<StateProps> = ({
  isAuthenticatedAzure,
  isAuthenticatedOrch,
}) => {
  console.log("Start HomeOrLogin Component");
  console.log("isAuthenticatedAzure: " + isAuthenticatedAzure);
  console.log("isAuthenticatedOrch: " + isAuthenticatedOrch);
  // return isAuthenticatedAzure == "1" && isAuthenticatedOrch == "1" ? (
  return isAuthenticatedOrch === "1" ? (
    <Redirect to="/home" />
  ) : (
    <Redirect to="/login" />
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    isAuthenticatedAzure: state.user.isAuthenticatedAzure, //Azure AD
    isAuthenticatedOrch: state.user.isAuthenticatedOrch, //JDE Orch
  }),
  component: HomeOrLogin,
});
