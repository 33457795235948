import React from "react";
import { IonCol, IonLabel, IonRow, IonSkeletonText } from "@ionic/react";

const PoaDetailsHeaderSkeleton: React.FC = (): JSX.Element => {
  return (
    <IonRow className="ion-text-center">
      <IonCol>
        <IonLabel>
          <h1>
            <IonSkeletonText
              animated
              style={{ width: "100px", height: "2em" }}
            />
          </h1>
          <IonSkeletonText animated style={{ width: "80px", height: "1em" }} />
          <br />
          <IonSkeletonText
            animated
            style={{ width: "30px", height: "1em" }}
          />{" "}
          -{" "}
          <IonSkeletonText animated style={{ width: "10px", height: "1em" }} />{" "}
          -{" "}
          <IonSkeletonText animated style={{ width: "35px", height: "1em" }} />
          <br />
          <br />
        </IonLabel>
      </IonCol>
    </IonRow>
  );
};

export default PoaDetailsHeaderSkeleton;
